<div class="container">
  <div>
    <!-- response error message div shown when API failure -->
    <div *ngIf="responseErrMessage">
      <div class="errorMsgRed">
        {{responseErrMessage}}
      </div>
    </div>
  </div>
  <!-- response error message div shown when API failure -->
  <!-- content of the page start-->
  <div *ngIf="!responseErrMessage">
    <div class="content-wrap">
      <div class="a3-wrap">
        <form [formGroup]="registrationForm" accept-charset="SHIFT_JIS">
          <div class="a4-wrap">
            <h2>{{'family-registration-input-page.heading' | translate}}</h2>
            <div class="methodOfConsent">
              <div class="mobile-text-align">
                <span>{{'family-registration-input-page.page-text.mcc.methodOfConfirmationOfConsent_first_text' |
                  translate}}
                </span>
                <span
                  class="methodOfConfirmationOfConsent_second_text">{{'family-registration-input-page.page-text.mcc.methodOfConfirmationOfConsent_second_text'
                  | translate}}
                </span>
              </div>
              <ng-select class="dropdwon" id="methodOfConsentDropdown" [searchable]="true"
                formControlName="methodOfConfirmationOfConsent">
                <ng-option *ngFor="let option of methodOfConfirmationOfConsent" [value]="option">
                  {{ option.dataValue }}
                </ng-option>
              </ng-select>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['methodOfConfirmationOfConsent'].errors?.['required'] || (this.registrationForm.controls['methodOfConfirmationOfConsent'].touched && registrationForm.controls['methodOfConfirmationOfConsent'].errors?.['required'])">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="relationship">
              <div class="mobile-text-align">
                <span>
                  {{'family-registration-input-page.page-text.relationship.relationship_first_text' | translate}}
                </span>
                <span class="relationship_second_text">
                  {{'family-registration-input-page.page-text.relationship.relationship_second_text' | translate}}
                </span>
              </div>
              <ng-select class="dropdwon" id="relationshipDropdown" [searchable]="true" formControlName="relationship">
                <ng-option *ngFor="let option of memberRelationship" [value]="option">
                  {{ option.dataValue }}
                </ng-option>
              </ng-select>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['relationship'].errors?.['required'] ||(this.registrationForm.controls['relationship'].touched && registrationForm.controls['relationship'].errors?.['required'])">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="row row-kanji" style="display: flex;">
              <!-- <div flex="50"> -->
              <div class="kanaName">
                <span class="kanjiNameLabel">{{'family-registration-input-page.page-text.kanji.firstName' | translate}}
                </span>
                <input type="text" id="firstName" class="form-control" value="" formControlName="kanjiLastName"
                  maxlength="50" style="width: 90%!important;">
                <div class="error-section"
                  *ngIf="(registrationForm.controls['kanjiLastName'].touched || registrationForm.controls['kanjiLastName'].invalid) && registrationForm.controls['kanjiLastName'].errors?.['pattern']">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidKanjiName' |
                    translate}}</span>
                </div>
                <div class="error-section"
                  *ngIf="showError && registrationForm.controls['kanjiLastName'].errors?.['required'] || (!this.registrationForm.controls['kanjiLastName'].value && this.registrationForm.controls['kanjiLastName'].touched )">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                    translate}}</span>
                </div>
              </div>
              <div class="kanaName">
                <span class="kanjiNameLabel">{{'family-registration-input-page.page-text.kanji.lastName' | translate}}
                </span>
                <input type="text" id="familyName" class="form-control" value="" formControlName="kanjiFirstName"
                  maxlength="50" style="width: 90%!important;">
                <div class="error-section"
                  *ngIf="(registrationForm.controls['kanjiFirstName'].touched || registrationForm.controls['kanjiFirstName'].invalid) && this.registrationForm.controls['kanjiFirstName'].errors?.['pattern'] ">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidKanjiName' |
                    translate}}</span>
                </div>
                <div class="error-section"
                  *ngIf="showError && this.registrationForm.controls['kanjiFirstName'].errors?.['required']  || (!this.registrationForm.controls['kanjiFirstName'].value && this.registrationForm.controls['kanjiFirstName'].touched )">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                    translate}}</span>
                </div>
              </div>
              <!-- </div> -->
            </div>
            <div>&nbsp;</div>
            <div class="row row-kana" style="display: flex;">
              <!-- <div flex="50"> -->
              <div class="kanaName">
                <span class="kanaNameLabel">{{'family-registration-input-page.page-text.kana.firstName' |
                  translate}}</span>
                <input type="text" id="firstName" class="form-control" value="" formControlName="kanaLastName"
                  maxlength="30" style="width: 90%!important;">
                <div class="error-section"
                  *ngIf="(registrationForm.controls['kanaLastName'].touched || registrationForm.controls['kanaLastName'].invalid) && registrationForm.controls['kanaLastName'].errors?.['pattern'] ">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidKanaName' |
                    translate}}</span>
                </div>
                <div class="error-section"
                  *ngIf="showError && registrationForm.controls['kanaLastName'].errors?.['required'] || (!this.registrationForm.controls['kanaLastName'].value && this.registrationForm.controls['kanaLastName'].touched )">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                    translate}}</span>
                </div>
              </div>
              <div class="kanaName">
                <span class="kanaNameLabel">{{'family-registration-input-page.page-text.kana.lastName' |
                  translate}}</span>
                <input type="text" id="familyName" class="form-control" value="" formControlName="kanaFirstName"
                  maxlength="30" style="width: 90%!important;">
                <div class="error-section"
                  *ngIf="(registrationForm.controls['kanaFirstName'].touched || registrationForm.controls['kanaFirstName'].invalid) && registrationForm.controls['kanaFirstName'].errors?.['pattern']">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidKanaName' |
                    translate}}</span>
                </div>
                <div class="error-section"
                  *ngIf="showError && registrationForm.controls['kanaFirstName'].errors?.['required'] || (!this.registrationForm.controls['kanaFirstName'].value && this.registrationForm.controls['kanaFirstName'].touched )">
                  <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                    translate}}</span>
                </div>
                <!--  -->

              </div>
            </div>
            <div>&nbsp;</div>
            <div class="row">
              <div class="gender">
                <span> {{'family-registration-input-page.page-text.gender.genderText' | translate}}</span>
                <ng-select class="dropdwon" id="genderDropdown" [searchable]="true" formControlName="gender">
                  <ng-option *ngFor="let option of memberGender" [value]="option">
                    {{ option._id }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['gender'].errors?.['required'] || (registrationForm.controls['gender'].touched && registrationForm.controls['gender'].errors?.['required']) ">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="date-picker">
              <div class="date-picker-label">
                <span class="labels mobile-text-align">
                  <span>{{'family-registration-input-page.page-text.DOB.date_of_birth_first_text' | translate}}</span>
                  <span>{{'family-registration-input-page.page-text.DOB.date_of_birth_second_text' | translate}}</span>
                </span>
              </div>
              <div class="date-picker-feild">
                <input class="form-control" id="datepicker" [matDatepicker]="picker" [max]="today"
                  (dateChange)="changeDate($event)" formControlName="dateOfBirth" style="width: 100%!important;" (blur)="formatDOB($event)"
                  appDateInputFormat>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <div class="error-section"
                *ngIf="registrationForm.controls['dateOfBirth'].touched && registrationForm.controls['dateOfBirth'].errors?.['minAge']">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.dateOfBirth' |
                  translate}}</span>
              </div>
              <div class="error-section"
                *ngIf="registrationForm.controls['dateOfBirth'].touched && registrationForm.controls['dateOfBirth'].errors?.['maxAge']">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.dateOfBirth' |
                  translate}}</span>
              </div>
              <div class="error-section"
                *ngIf="registrationForm.controls['dateOfBirth'].touched && registrationForm.controls['dateOfBirth'].invalid && registrationForm.controls['dateOfBirth'].errors?.['matDatepickerParse'] && registrationForm.controls['dateOfBirth'].errors?.['required']">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.dateOfBirth' |
                  translate}}</span>
              </div>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['dateOfBirth'].errors?.['required'] || (registrationForm.controls['dateOfBirth'].touched && registrationForm.controls['dateOfBirth'].errors?.['required'] && !registrationForm.controls['dateOfBirth'].errors?.['matDatepickerParse'])">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="zipCode">
              <div> {{'family-registration-input-page.page-text.zipCode.zipCodeText' | translate}}</div>
              <input type="text" id="zipcode" class="form-control" value="" formControlName="zipCode" minlength="6"
                maxlength="8" (blur)="addHyphen($event)" appJapaneseInput>
              <div class="error-section"
                *ngIf="registrationForm.controls['zipCode'].touched && registrationForm.controls['zipCode'].invalid && !registrationForm.controls['zipCode'].errors?.['required'] && !zipCodeErrMessage">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidZipCode' |
                  translate}}
                </span>
              </div>
              <div class="error-section" *ngIf="zipCodeErrMessage">
                <span class="error"> {{zipCodeErrMessage}}
                </span>
              </div>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['zipCode'].errors?.['required'] || (registrationForm.controls['zipCode'].touched && registrationForm.controls['zipCode'].errors?.['required'])">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="address">
              <div> {{'family-registration-input-page.page-text.firstAddress.firstAddressText' | translate}}</div>
              <ng-select class="dropdwon" id="addressDropdown" [searchable]="true" formControlName="firstAddress"
                required>
                <ng-option *ngFor="let option of addressResponseList" [value]="option">
                  {{option?.addressKanji}}
                </ng-option>
              </ng-select>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['firstAddress'].errors?.['required'] || (registrationForm.controls['firstAddress'].touched && registrationForm.controls['firstAddress'].errors?.['required']) ">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="secondAddress">
              <div> {{'family-registration-input-page.page-text.secondAddress.secondAddressTextNew' | translate}}</div>
              <!-- <textarea type="text" id="secondaddress" class="form-control text-area" value=""
                formControlName="secondAddress" maxlength="255">
              </textarea> -->
              <input type="text" id="secondaddress" class="form-control" value="" formControlName="secondAddress"
                maxlength="255">

              <div class="error-section"
                *ngIf="(registrationForm.controls['secondAddress'].touched || registrationForm.controls['secondAddress'].invalid) && registrationForm.controls['secondAddress'].errors?.['pattern']">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.hasAddress' |
                  translate}}</span>
              </div>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['secondAddress'].errors?.['required'] || (registrationForm.controls['secondAddress'].touched && registrationForm.controls['secondAddress'].errors?.['required'])">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>
            <div>&nbsp;</div>
            <div class="date-picker">
              <div class="date-picker-label">
                <div> {{'family-registration-input-page.page-text.telephone.telephoneTextNew' | translate}}</div>
              </div>
              <div class="phone-number-feild">
                <div class="first-box">
                  <div>
                    <input id="firstInput" class="form-control tel-box" formControlName="telephoneFieldOne"
                      minlength="2" maxlength="5" (input)="onBlurTelephone()" required appOnlyNumbers />
                  </div>
                </div>
                <span class="hyphen-align">-</span>
                <div class="second-box">
                  <div>
                    <input id="secondInput" class="form-control tel-box" formControlName="telephoneFieldTwo"
                      minlength="2" maxlength="4" (input)="onBlurTelephone()" required appOnlyNumbers />
                  </div>
                </div>
                <span class="hyphen-align">-</span>
                <div class="third-box">
                  <div>
                    <input id="thirdInput" class="form-control tel-box" formControlName="telephoneFieldThree"
                      minlength="2" maxlength="4" (input)="onBlurTelephone()" required appOnlyNumbers />
                  </div>
                </div>
              </div>
              <input style="display: none;" type="text" id="tel" class="form-control" value=""
                formControlName="telephone" minlength="10" maxlength="11">
              <div class="error-section" *ngIf="((registrationForm.controls['telephoneFieldOne'].touched &&
                registrationForm.controls['telephoneFieldOne'].invalid && 
                registrationForm.controls['telephoneFieldOne'].errors?.['pattern']) ||
                (registrationForm.controls['telephoneFieldTwo'].touched  &&
                registrationForm.controls['telephoneFieldTwo'].invalid && 
                registrationForm.controls['telephoneFieldTwo'].errors?.['pattern']) ||
                (registrationForm.controls['telephoneFieldThree'].touched && 
                registrationForm.controls['telephoneFieldThree'].invalid && 
                registrationForm.controls['telephoneFieldThree'].errors?.['pattern'])) || phoneNumberCheckValidation ">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.hasTelephone' |
                  translate}}</span>
              </div>
              <div class="error-section"
                *ngIf="showError && registrationForm.controls['telephoneFieldOne'].errors?.['required'] || (!this.registrationForm.controls['telephoneFieldOne'].value && this.registrationForm.controls['telephoneFieldOne'].touched ) && 
                registrationForm.controls['telephoneFieldTwo'].errors?.['required'] || (!this.registrationForm.controls['telephoneFieldTwo'].value && this.registrationForm.controls['telephoneFieldTwo'].touched )  && 
                registrationForm.controls['telephoneFieldThree'].errors?.['required'] || (!this.registrationForm.controls['telephoneFieldThree'].value && this.registrationForm.controls['telephoneFieldThree'].touched )">
                <span class="error"> {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                  translate}}</span>
              </div>
            </div>


            <div>&nbsp;</div>

          </div>
          <div class="a2-wrap">
            <div class="btn-wrap">
              <button mat-flat-button type="button" color="accent" class="back-custom-btn-primary"
                [routerLink]="['../select-members']">
                {{'family-registration-input-page.page-text.button.primaryBtnText' | translate}}
              </button>
              <button mat-flat-button color="primary" class="custom-btn-primary" (click)="submitForm()">
                {{'family-registration-input-page.page-text.button.secondaryBtnText' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- content of the page end-->
</div>