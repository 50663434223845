<div class="container">
    <div>
        <!-- response error message div shown when API failure -->
        <div *ngIf="responseErrMessage">
            <div class="errorMsgRed">
                {{responseErrMessage}}
            </div>
        </div>
    </div>
    <!-- response error message div shown when API failure -->
    <!-- content of the page start-->
    <div *ngIf="!responseErrMessage">
        <h2 class="rakuraku-sub-heading-1">{{'family-registration-home.a2.heading' | translate}}</h2>
        <p class="rakuraku-sub-heading">{{'family-registration-home.a2.list.one' | translate}}</p>
        <span class="rakuraku-sub-heading">{{'family-registration-home.a2.list.two' | translate}}</span>
        <span class="rakuraku-sub-heading sub-heading-two-1">{{'family-registration-home.a2.list.two-1' | translate}}</span>
        <p class="rakuraku-sub-heading">{{'family-registration-home.a2.list.three' | translate}} <a
                href="https://www.manulife.co.jp/content/dam/insurance/jp/documents/procedure-attorney/rakurakukazoku.pdf"
                target="_blank" class="a2-link">{{'family-registration-home.a2.list.four' | translate}}</a></p>
        <div class="a2-wrap">
            <div class="btn-wrap">
                <button mat-flat-button color="primary" class="custom-btn-primary"
                    [routerLink]="['../select-policy']">{{'family-registration-home.a2.primaryBtnText' |
                    translate}}</button>
            </div>
        </div>
        <div class="content-wrap">

            <div class="a4-wrap" *ngIf="!responseErrMessage">
                <h2 class="petName-heading">{{'family-registration-home.a4.heading' | translate}}</h2>
                <div class="main-list">
                    <ol class="ordered-list" *ngFor="let policy of myResponseList, let i= index">
                        <div class="row">
                            <div class="col-12 text-font">{{policy.petName}}</div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-home.a4.list.one' | translate}}</span>
                                    <span class="value mobile-appearance-number">{{policy.policyNumber}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-home.a4.list.two' | translate}}</span>
                                    <span class="value">{{policy.insuredPersonNameKana}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="policy.familyMember?.kanjiLastName && (policy.familyRegistrationStatus === 'Registered'||policy.familyRegistrationStatus === 'Procedure Not Possible')">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-home.a4.list.three' | translate}}</span>
                                    <span class="value">
                                        <span class="name-align">
                                            {{policy.familyMember?.kanjiLastName}}
                                        </span> 
                                        <span
                                            class="name-align">
                                            {{policy.familyMember?.kanjiFirstName}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label"
                                        *ngIf="policy.familyRegistrationStatus =='Not Registered'">{{'family-registration-home.a4.list.three'
                                        | translate}}</span>
                                    <span class="label"
                                        *ngIf="policy.familyRegistrationStatus =='In Progress'">{{'family-registration-home.a4.list.three'
                                        | translate}}</span>
                                    <span class="label"
                                        *ngIf="policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Registered'"></span>
                                    <span class="label"
                                        *ngIf="policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Procedure Not Possible'"></span>
                                    <span class="label"
                                        *ngIf="!policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Registered'">
                                        {{'family-registration-home.a4.list.three' | translate}}
                                    </span>
                                    <span class="label"
                                        *ngIf="!policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Procedure Not Possible'">
                                        {{'family-registration-home.a4.list.three' | translate}}</span>
                                    <span class="value" *ngIf="policy.familyRegistrationStatus === 'Not Registered'">
                                        <span
                                            style="color: rgb(244, 67, 54)">{{'family-registration-home.a4.status.Unregistered'
                                            | translate}}</span>
                                    </span>
                                    <span class="value" *ngIf="policy.familyRegistrationStatus === 'In Progress' && policy.policyStatus != '失効'">
                                        <span style="color: grey">{{'family-registration-home.a4.status.In_process' |
                                            translate}}</span>
                                    </span>
                                    <span class="value"
                                        *ngIf="policy.familyRegistrationStatus === 'In Progress' && policy.policyStatus === '失効'">
                                        <span style="color: grey">{{'family-registration-home.a4.status.Procedure Not
                                            Possible'
                                            | translate}}</span>
                                    </span>
                                    <span class="value"
                                        *ngIf="policy.familyRegistrationStatus === 'Procedure Not Possible'">
                                        <span style="color: grey">{{'family-registration-home.a4.status.Procedure Not
                                            Possible'
                                            | translate}}</span>
                                    </span>

                                    <span class="value" *ngIf="policy.familyRegistrationStatus === 'Registered'">
                                        <a style="color: green; cursor: pointer; text-decoration: underline;"
                                            (click)="openTermsConditionsPopUp(policy)">{{'family-registration-home.a4.status.Check_registration_information'
                                            | translate}}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ol>
                </div>
            </div>
        </div>
        <div class="a2-wrap">
            <div class="btn-wrap">
                <button mat-flat-button color="accent" class="back-custom-btn-primary"
                    (click)="redirectToCWS()">{{'family-registration-home.a2.secondaryBtnText.two' |
                    translate}}</button>
            </div>
        </div>
    </div>
    <!-- content of the page end-->
</div>

<!-- popup for registered members  -->
<div class="modal" id="termsConditions">
    <div class="container">
        <div class="modal-content">
            <div class="modal-body-footer-spacing">
                <div class="modal-header regBoxHead">
                    <!-- {{'family-registration-home.popup.heading' | translate}} -->
                    <span class="name-align-modal kana-name-align-content">
                        <span class="name-align-popup name-align-popup-kana">{{myFamilyMemberObj?.kanjiLastName}}</span>
                    </span>
                    <span class="name-align-modal kana-name-align-content">
                        <span class="name-align-popup name-align-popup-kana">{{myFamilyMemberObj?.kanjiFirstName}}</span>
                    </span>
                </div>
                <div class="noBottomPaddingLi cust-personal-info">
                    <div class="custom-width" *ngIf="myFamilyMemberObj">
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.one-name' |
                                        translate}}</span>
                                    <span class="value kana-name-align-popup">
                                        <span class="name-align-modal kana-name-align-content">
                                            <span class="name-align-popup">{{myFamilyMemberObj?.kanaLastName}}</span>
                                        </span>
                                        <span class="name-align-modal kana-name-align-content">
                                            <span class="name-align-popup">{{myFamilyMemberObj?.kanaFirstName}} </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.two' |
                                        translate}}</span>
                                    <span class="value">{{myFamilyMemberObj?.dob | customDateFormat}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.three' |
                                        translate}}</span>
                                    <span *ngIf="myFamilyMemberObj?.gender == '男'" class="value">男性</span>
                                    <span *ngIf="myFamilyMemberObj?.gender == '女'" class="value">女性</span>
                                    <!-- <span class="value">{{myFamilyMemberObj?.gender}}</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.four' |
                                        translate}}</span>
                                    <!-- <span *ngIf="myFamilyMemberObj?.relationship == '叔父'" class="value">伯叔父</span>
                                    <span *ngIf="myFamilyMemberObj?.relationship == '叔母'" class="value">伯叔母</span> -->
                                    <span class="value">{{myFamilyMemberObj?.relationship}}</span>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.five' |
                                        translate}}</span>
                                    <span class="value"> {{'〒'+ myFamilyMemberObj?.address?.zipCode}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label"></span>
                                    <span class="value"> {{myFamilyMemberObj?.address?.addressDesc2}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label"></span>
                                    <span class="value address-wrap name-align-modal">
                                        <span class="address-align-popup">
                                            {{myFamilyMemberObj?.address?.addressDesc1}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="card-family">
                                <div class="field">
                                    <span class="label">{{'family-registration-select-policy.popup.list.six' |
                                        translate}}</span>
                                    <span class="value mobile-appearance-number">{{myFamilyMemberObj?.phoneNumber}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="a2-wrap">
                    <div class="btn-wrap-family">
                        <button mat-flat-button color="accent" class="back-custom-btn-primary"
                            (click)="closeTermsConditionsPopUp()">{{'family-registration-home.popup.secondaryBtnText'
                            | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end -->