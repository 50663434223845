<div class="container">
    <!-- <span class="ssl-info"><span><i class="fa fa-lock" style="font-size:19px;color:gray"></i></span></span> -->
    <h1>{{'family-registration-select-policy.a2.heading' | translate}}</h1>
    <ul class="c-flow">
        <li class="first-breadcrumbs">{{'family-registration-select-policy.a2.breadcrumbs.one' | translate}}</li>
        <li class="is-current">{{'family-registration-select-policy.a2.breadcrumbs.two' | translate}}</li>
        <li>{{'family-registration-select-policy.a2.breadcrumbs.three' | translate}}</li>
        <li>{{'family-registration-select-policy.a2.breadcrumbs.four' | translate}}</li>
    </ul>
    <h2>{{'family-registration-select-policy.select-family-member.sub-h1' | translate}}</h2>
    <div class="content-wrap">
        <div class="a3-wrap">
            <p style="text-align: left;" class="mobile-text-align">
                <span>
                    {{'family-registration-select-policy.select-family-member.sub-h2.first' | translate}}
                </span>
                <span class="sub-h2-second">
                    {{'family-registration-select-policy.select-family-member.sub-h2.second' | translate}}
                </span>
            </p>
            <div class="btn-wrap card-cover">
                <p>{{'family-registration-select-policy.select-family-member.sub-h4' | translate}}</p>

                <button mat-flat-button color="primary" class="custom-btn-primary"
                    [routerLink]="['../input-page']">{{'family-registration-select-policy.select-family-member.selectMemberText'
                    | translate}}</button>
            </div>
        </div>
        <div class="a4-wrap" *ngIf="check_registed_member==false">
            <!-- <h2>{{'e-tax-home.a4.heading' | translate}}</h2> -->
            <p style="text-align: left;" class="mobile-text-align">
                <span>
                    {{'family-registration-select-policy.select-family-member.sub-h3.first' | translate}}
                </span>
                <span class="sub-h3-second">
                    {{'family-registration-select-policy.select-family-member.sub-h3.second' | translate}}
                </span>
            </p>
            <div class="main-list">
                <ol class="ordered-list" *ngFor="let member of sorted_distinct_list, let i=index">
                    <div class="row" style="margin-bottom: 10px; font-size: larger; display: flex;">
                        <div class="col-5">
                            <!-- <input type="radio" (change)="onChange($event, member)" name="selectMember" /> -->
                            <input type="radio" [id]="i" class="custom-radio-btn" (change)="onChange($event, member)"
                                name="selectMember">
                            <label [for]="i" class="custom-radio-label"></label>
                        </div>
                        <div class="col-7">
                            <div class="main-content-flex">
                                <div class="main-content-name">
                                    <span class="name-align">{{member.familyMember?.kanjiLastName}}</span> 
                                    <span class="name-align">{{member.familyMember?.kanjiFirstName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.one-name' |
                                    translate}}</span>
                                <span class="value">
                                    <span class="name-align">{{member.familyMember?.kanaLastName}}</span>
                                    <span class="name-align">{{member.familyMember?.kanaFirstName}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.two' |
                                    translate}}</span>
                                <span class="value">{{member.familyMember?.dob | customDateFormat}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.three' |
                                    translate}}</span>
                                    <span *ngIf="member.familyMember?.gender == '男'" class="value">男性</span>
                                    <span *ngIf="member.familyMember?.gender == '女'" class="value">女性</span>
                                <!-- <span class="value">{{member.familyMember?.gender}}</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.four' |
                                    translate}}</span>
                                <!-- <span *ngIf="member.familyMember?.relationship == '叔父'" class="value">伯叔父</span>
                                <span *ngIf="member.familyMember?.relationship == '叔母'" class="value">伯叔母</span> -->
                                <span class="value">{{member.familyMember?.relationship}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.five' |
                                    translate}}</span>
                                <span class="value"> {{'〒'+ member.familyMember?.address?.zipCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label"></span>
                                <span class="value name-align"> {{member.familyMember?.address?.addressDesc2}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label"></span>
                                <span class="value address-wrap name-align-address">
                                    <span class="name-align-address-content ">
                                        {{member.familyMember?.address?.addressDesc1}}
                                    </span>
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-select-policy.popup.list.six' |
                                    translate}}</span>
                                <span class="value mobile-appearance-number">{{member.familyMember?.phoneNumber}}</span>
                            </div>
                        </div>
                    </div>
                </ol>
            </div>
        </div>
    </div>
    <div class="a2-wrap">
        <div class="btn-wrap-family">
            <button mat-flat-button color="accent" class="back-custom-btn-primary" (click)="prevPage()">
                {{'family-registration-select-policy.select-family-member.primaryBtnText' | translate}}</button>
            <button *ngIf="check_registed_member==false" [disabled]="isChecked==false" mat-flat-button color="primary"
                class="custom-btn-primary"
                [routerLink]="['../confirmation-page']">{{'family-registration-select-policy.select-family-member.secondaryBtnText'
                | translate}}</button>
        </div>
    </div>
</div>