export const environment = {
    production: false,
    authority: 'www.test.salesforce.com',
    redirectUrl: 'https://uat.selfservice.mypage.manulife.co.jp',
    authWellknownEndpointUrl: 'https://mljsit-cws-ohana.manulife.co.jp',
    clientId: '3MVG9Gdzj3taRxuMATa3R8PqUPIksbH3R8PNEhSqUTUoGz1sl4kxyQDE4lgcIajWXA4Yyr32t_ohiEYeRbZFu',
    scope: 'openid api',
    responseType: 'code',
  };

  export const common_urls = {
   bffBaseUrl: 'https://sit.apps.manulife.co.jp/ext/sit4/cws/bff/api/v1',
    cwsBaseUrl: 'https://mljsit-cws-ohana.manulife.co.jp',
    surveyUrl: '', // not in use
    footerNotesUrl: 'https://mljsit-cws-ohana.manulife.co.jp/resource/1668755720000',
    footerTermsOfUseUrl: 'https://mljsit-cws-ohana.manulife.co.jp/resource/1726550001000',
    oodMenuItemsUrl: "https://sit.apps.manulife.co.jp/ext/sit3/cws/bff/api/v1",
    adobeAnalyticsUrl: 'https://assets.adobedtm.com/b84dbf01908c/ee406859b983/launch-3ba032760f58-development.min.js'
  }
  export const common_url_sur = {
    bffBaseSurUrl:'https://uat.apps.manulife.co.jp/ext/cws-sur/bff/api/v1',
    cwsLoginUrl:"https://mlj--uat02.sandbox.my.site.com/cws/",
    publicKey: `I6yYGTawjAAIVS7Bzv0KUdHjmL02xODrGSJm1rQAGGQ=`
}

export const common_urls_gasa = {
    bffBaseUrl:'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1/gasa',
    cwsBaseUrl:'https://mljsit-cws-ohana.manulife.co.jp',
}

export const ereport_urls = {
  bffBaseEreportUrl: 'https://sit.apps.manulife.co.jp/ext/fraereport/cws2/bff/api/v1'
}

 export const common_urls_c360 = {
    bffBaseUrl:'https://sit.apps.manulife.co.jp/ext/cws2-c360/bff/api/v1'
 }
export const stop_mailing_certificates_url = {
  bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1',
  cwsBaseUrl: 'https://mljsit-cws-ohana.manulife.co.jp',
  errorPage: '/error_page'
}
