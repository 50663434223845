export const environment = {
  production: false,
  authority: 'www.test.salesforce.com',
  redirectUrl: 'https://uat.selfservice.mypage.manulife.co.jp',
  authWellknownEndpointUrl: 'https://mljuat-cws-ohana.manulife.co.jp',
  clientId: '3MVG9Gdzj3taRxuOdPUSQOWCsZIXLfn11hwxwy803fWMsn5H.6YGRKmlBvlZYMjhZQ8QSOUBwYAF6p0Z6.9W4',
  scope: 'openid api',
  responseType: 'code',
};

  export const common_urls = {
   bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1',
    cwsBaseUrl: 'https://mljuat-cws-ohana.manulife.co.jp',
    surveyUrl: '', // not in use
    footerNotesUrl: 'https://mljuat-cws-ohana.manulife.co.jp/resource/1668755720000',
    footerTermsOfUseUrl: 'https://mljuat-cws-ohana.manulife.co.jp/resource/1726550001000',
    oodMenuItemsUrl: "https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1",
    adobeAnalyticsUrl: 'https://assets.adobedtm.com/b84dbf01908c/ee406859b983/launch-3ba032760f58-development.min.js'
  }
  export const common_url_sur = {
    bffBaseSurUrl:'https://uat.apps.manulife.co.jp/ext/cws-sur/bff/api/v1',
    cwsLoginUrl:"https://mljuat-cws-ohana.manulife.co.jp/cws/",
    publicKey: `I6yYGTawjAAIVS7Bzv0KUdHjmL02xODrGSJm1rQAGGQ=`
}

export const common_urls_gasa = {
  bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1',
  cwsBaseUrl: 'https://mljuat-cws-ohana.manulife.co.jp/cws/',
}

export const ereport_urls = {
  bffBaseEreportUrl: 'https://uat.apps.manulife.co.jp/ext/fraereport/bff/api/v1'
}

export const common_urls_c360 = {
  bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws2-c360/bff/api/v1'
}
export const stop_mailing_certificates_url = {
  bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1',
  cwsBaseUrl: 'https://mljuat-cws-ohana.manulife.co.jp',
  errorPage: '/error_page'
}

export const stp_cws_urls = {
  baseUrl: 'https://uat.apps.manulife.co.jp/ext/cws-sur/bff/api/v1',
  cwsLoginUrl: "https://mljuat-cws-ohana.manulife.co.jp/cws/",
  publicKey: 'I6yYGTawjAAIVS7Bzv0KUdHjmL02xODrGSJm1rQAGGQ=',
  bffBaseUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/stp/api/v1',
  oodMenuItemsUrl: 'https://uat.apps.manulife.co.jp/ext/cws/bff/api/v1'
}
