import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable, retry, switchMap } from 'rxjs';
import { common_urls } from 'src/environments/environment';
import {
  Policy,
  PolicyXmlResponse,
} from '../modals/interfaces/policy-xml.model-model';
import { create_UUID } from '../utils/commons';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private bffBaseUrl: String = common_urls.bffBaseUrl;
  private readonly maxRetryCount = 2; // Maximum number of retries

  constructor(private httpClient: HttpClient, private oidcSecurityService: OidcSecurityService) {}

  fetchPolicies(taxYear: string): Observable<Array<Policy>> {
    const cwsTraceID = create_UUID();
    const apiEndpoint = `${this.bffBaseUrl}/policy-xml/${taxYear}/${cwsTraceID}`;

    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
      };
      return this.httpClient
      .get<PolicyXmlResponse>(apiEndpoint, requestOptions)
      .pipe(
        map((res) => {
          sessionStorage.setItem('policyXmlData', JSON.stringify(res));
          return res.policies.filter((item, index, self) => {
            return index === self.findIndex((obj) => obj.polNo === item.polNo);
          });
        })
      );
      }),
      retry({
        count: 2,
        delay: 2000,
        resetOnSuccess: true
      })
    )
  }
}
