<div class="container">
    <h1>{{'family-registration-completion-page.a2.heading' | translate}}</h1>
    <ul class="c-flow">
        <li class="first-breadcrumbs">{{'family-registration-completion-page.a2.breadcrumbs.one' | translate}}</li>
        <li>{{'family-registration-completion-page.a2.breadcrumbs.two' | translate}}</li>
        <li>{{'family-registration-completion-page.a2.breadcrumbs.three' | translate}}</li>
        <li class="is-current">{{'family-registration-completion-page.a2.breadcrumbs.four' | translate}}</li>
    </ul>
    <h2>{{'family-registration-completion-page.a3.heading.one' | translate}}</h2>
    <p>{{'family-registration-completion-page.a3.heading.two' | translate}}</p>
    <div class="a2-wrap">
        <div class="btn-wrap">
            <button mat-flat-button color="accent" class="back-custom-btn-primary" (click)="redirectToCWS()">{{'family-registration-completion-page.a2.secondaryBtnText' | translate}}</button>
        </div>
    </div>
</div>