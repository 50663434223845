import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, take } from 'rxjs';
import { UserData } from 'src/app/modals/interfaces/user-data-model';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LoaderService } from 'src/app/service/loader.service';
import { PolicyService } from 'src/app/service/policy.service';
import { common_urls } from 'src/environments/environment';
import { memberRelationship, errorMessageRegistration } from 'src/app/shared/constants-c360';
@Component({
  selector: 'app-family-registration',
  templateUrl: './family-registration.component.html',
  styleUrls: ['./family-registration.component.scss']
})
export class FamilyRegistrationComponent implements OnInit {
  queryParamsSubscription!: Subscription;
  pageTitle: string = '';
  myResponseList: any;
  registeredFamilyMember: any;
  myFamilyMemberObj: any;
  mockResponseList: any;
  CWSID: any;
  obtainedResponse: any;
  responseErrMessage: any;

  constructor(private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
    private inactivityService: InactivityService, private loaderService: LoaderService,
    private translateService: TranslateService, private titleService: Title,
    private cwsRedirectService: CwsRedirectService, private familyResigstarionService: FamilyRegistrationService, private router: Router, private policyService: PolicyService) { }

  ngOnInit(): void {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.loaderService.hide();
    this.inactivityService.startTrackingInactivity();
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
    });
    const snapQueryParamURL = this.route.snapshot.queryParams['fromXmlDownload'];
    if (!snapQueryParamURL) this.checkUrlParams();
    this.getFamilyRegDetails();
  }

  /* Function to subscribe for url params */
  checkUrlParams(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      if (params['cwsBackUrl']) {
        const cwsParam = params['cws'];
        const policyId = params['id'];
        const backUrl = params['cwsBackUrl'];
        const procUrl = params['procUrl'];

        const cwsBackUrl: string = cwsParam ? `${backUrl}&cws=${cwsParam}` : `${backUrl}&id=${policyId}`;

        const procUrlFinal = procUrl ? procUrl : `${common_urls.cwsBaseUrl}/Home?from=auth&cws=1`;

        sessionStorage.setItem('cwsBackUrl', encodeURIComponent(cwsBackUrl));
        sessionStorage.setItem('procUrl', encodeURIComponent(procUrlFinal));
      } else {
        const cwsBackUrl: string = `${common_urls.cwsBaseUrl}/Home?from=auth&cws=1`;
        const url = encodeURIComponent(cwsBackUrl);
        sessionStorage.setItem('cwsBackUrl', url);
        sessionStorage.setItem('procUrl', url);
      }
    })
  }

  getFamilyRegDetails() {
    this.loaderService.show();
    this.familyResigstarionService.getFamilyRegPolicyDetails().subscribe((res) => {
      this.loaderService.hide();
      let response = res;
      if (response) {
        const res = response;
        
        if (response.policies.length <= 0) {
          this.responseErrMessage = errorMessageRegistration.errMessageNoRecord.Err_404;
        }

        response.policies.sort((a:any,b:any)=>{
          return new Date(b.policyIssueDate).getTime()- new Date(a.policyIssueDate).getTime();
        })

        response.policies.forEach((a: any) => {
          memberRelationship.forEach((b: any) => {
            if (a.familyMember && a.familyMember.relationship) {
              if (a.familyMember.relationship.toLowerCase() ===
                b.engValue.toLowerCase()) {
                a.familyMember.relationship = b.japValue;
              }
            }
          })
        });
        this.myResponseList = response.policies;
        this.familyResigstarionService.parentData = response;
      }
    }, error => {
      console.log(error);
      if (error.status === 500 || error.status === 503 || error.status === 504)  {
        this.responseErrMessage = errorMessageRegistration.responseErrMessage.Err_500_503_504;
      }else if(error.status === 400 ){
        this.responseErrMessage = errorMessageRegistration.errMessageBadRequest.Err_400;
      }else if(error.status === 404){
        this.responseErrMessage = errorMessageRegistration.errMessageNoRecord.Err_404;
      }
      this.loaderService.hide();
    });
  }



  /* function to redirect user on cws */
  redirectToCWS() {
    this.cwsRedirectService.redirectToCWSBack();
  }

  // Modal open

  openModal(newValue: any) {
    this.myFamilyMemberObj = newValue.familyMember;
    const element = document.getElementById('termsConditions');
    element?.classList.add('open');
  }

  hideModal() {
    const element = document.getElementById('termsConditions');
    element?.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

  openTermsConditionsPopUp(onSelectValue: any) {
    this.openModal(onSelectValue);
  }

  closeTermsConditionsPopUp() {
    this.hideModal();
  }

}
