<div class="container">
    <h1>{{'family-registration-select-policy.a2.heading' | translate}}</h1>
    <ul class="c-flow">
        <li class="is-current">{{'family-registration-select-policy.a2.breadcrumbs.one' | translate}}</li>
        <li>{{'family-registration-select-policy.a2.breadcrumbs.two' | translate}}</li>
        <li>{{'family-registration-select-policy.a2.breadcrumbs.three' | translate}}</li>
        <li>{{'family-registration-select-policy.a2.breadcrumbs.four' | translate}}</li>
    </ul>
    <h2>{{'family-registration-select-policy.a3.heading' | translate}}</h2>
    <div class="content-wrap">
        <div class="a4-wrap">
            <p class="rakuraku-sub-heading">{{'family-registration-select-policy.a3.list.one' | translate}}</p>
            <p class="rakuraku-sub-heading">{{'family-registration-select-policy.a3.list.two' | translate}}</p>
            <p class="rakuraku-sub-heading-long-text">
                <span>
                    {{'family-registration-select-policy.a3.list.three-updated.first' |
                    translate}}</span>
                <span class="second-sub-heading">
                    {{'family-registration-select-policy.a3.list.three-updated.second' |
                    translate}}
                </span>
            </p>
            <div class="main-list">
                <ol class="ordered-list" *ngFor="let policy of myResponseList, let i= index">
                    <div class="row" style="font-size: larger; display: flex;">
                        <div class="col-5">
                            <div class="form-group">
                                <input type="checkbox" [id]="i"
                                    [disabled]="(policy.familyRegistrationStatus !== 'Not Registered') && (policy.familyRegistrationStatus !== 'Registered')"
                                    (change)="onChange($event, policy)">
                                <label [for]="i"></label>
                            </div>
                        </div>
                        <div class="col-7 text-font">{{policy.petName}}</div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-home.a4.list.one' | translate}}</span>
                                <span class="value mobile-appearance-number">{{policy.policyNumber}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-home.a4.list.two' | translate}}</span>
                                <span class="value">{{policy.insuredPersonNameKana}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="policy.familyMember?.kanjiLastName && (policy.familyRegistrationStatus === 'Registered'||policy.familyRegistrationStatus === 'Procedure Not Possible')">
                        <div class="card-family">
                            <div class="field">
                                <span class="label">{{'family-registration-home.a4.list.three' | translate}}</span>
                                <span class="value">
                                    <span class="name-align">{{policy.familyMember?.kanjiLastName}}</span>
                                    <span class="name-align">{{policy.familyMember?.kanjiFirstName}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-family">
                            <div class="field">
                                <span class="label"
                                    *ngIf="policy.familyRegistrationStatus =='Not Registered'">{{'family-registration-home.a4.list.three'
                                    | translate}}</span>
                                <span class="label"
                                    *ngIf="policy.familyRegistrationStatus =='In Progress'">{{'family-registration-home.a4.list.three'
                                    | translate}}</span>
                                <!-- <span class="label" *ngIf="policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Registered'"></span> -->
                                <span class="label"
                                    *ngIf="policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Procedure Not Possible'"></span>
                                <!-- <span class="label" *ngIf="!policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Registered'">
                                        {{'family-registration-home.a4.list.three' | translate}}
                                    </span> -->
                                <span class="label"
                                    *ngIf="!policy.familyMember?.kanjiLastName && policy.familyRegistrationStatus ==='Procedure Not Possible'">
                                    {{'family-registration-home.a4.list.three' | translate}}</span>
                                <span class="value" *ngIf="policy.familyRegistrationStatus === 'Not Registered'">
                                    <span
                                        style="color: rgb(244, 67, 54)">{{'family-registration-home.a4.status.Unregistered'
                                        | translate}}</span>
                                </span>
                                <span class="value"
                                    *ngIf="policy.familyRegistrationStatus === 'In Progress' && policy.policyStatus != '失効'">
                                    <span style="color: grey">{{'family-registration-home.a4.status.In_process' |
                                        translate}}</span>
                                </span>
                                <span class="value"
                                    *ngIf="policy.familyRegistrationStatus === 'In Progress' && policy.policyStatus === '失効'">
                                    <span style="color: grey">{{'family-registration-home.a4.status.Procedure Not
                                        Possible'
                                        | translate}}</span>
                                </span>
                                <span class="value"
                                    *ngIf="policy.familyRegistrationStatus === 'Procedure Not Possible'">
                                    <span style="color: grey">{{'family-registration-home.a4.status.Procedure Not
                                        Possible'
                                        | translate}}</span>
                                </span>
                                <!-- <span class="value"*ngIf="policy.familyRegistrationStatus === 'Registered'">
                                    <span style="color: green; text-decoration: underline;">{{'family-registration-home.a4.status.Check_registration_information' | translate}}</span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                </ol>
            </div>
        </div>
    </div>
    <div class="a2-wrap">
        <div class="btn-wrap">
            <button mat-flat-button color="accent" class="back-custom-btn-primary"
                [routerLink]="['../home']">{{'family-registration-select-policy.a2.secondaryBtnText' |
                translate}}</button>
            <button mat-flat-button [disabled]="newPolicyArray.length == 0" color="primary" class="custom-btn-primary"
                [routerLink]="['../select-members']">{{'family-registration-select-policy.a2.primaryBtnText' |
                translate}}</button>
        </div>
    </div>
</div>