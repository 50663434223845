import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LoaderService } from 'src/app/service/loader.service';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { Route } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PlatformLocation } from '@angular/common';
import { seccessor_order_ja } from 'src/app/shared/constants-c360';
@Component({
  selector: 'app-select-member',
  templateUrl: './select-member.component.html',
  styleUrls: ['./select-member.component.scss']
})
export class SelectMemberComponent implements OnInit {
  queryParamsSubscription!: Subscription;
  pageTitle: string = '';
  show: boolean = false;
  isChecked: boolean = false;
  prevSelectedPolicyNumber: any;
  selectedPolicyNumber: any;
  prevSelectedMemberBooleanMemberPage: boolean = false;
  constructor(private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
    private inactivityService: InactivityService, private loaderService: LoaderService,
    private translateService: TranslateService, private titleService: Title,
    private cwsRedirectService: CwsRedirectService, private familyResigstarionService: FamilyRegistrationService, private router: Router) { }



  ngOnInit(): void {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.loaderService.hide();
    this.inactivityService.startTrackingInactivity();
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
    });
    this.getFamilyMembers();
  }

  updated_member_response: any
  raku_raku_family_member: any;
  check_registed_member: boolean = false;
  newPolicyArray: any[] = [];
  tempArray: any[] = [];
  selected_member: any;
  res_for_spec: any;
  sortedList: any;
  sorted_distinct_list: any;
  registered_members:any;
  getFamilyMembers() {
    let memberlist_ja;
    let member_list_response;
    let familyMemberList;
    member_list_response = this.familyResigstarionService.parentData;
    familyMemberList = [...member_list_response.policies];
    // removing null members
    memberlist_ja = familyMemberList.filter((el: { familyMember: null; }) => el.familyMember != null);

    //sorting based on relationship successor order
    memberlist_ja.sort((a: any, b: any) => {
      const relation1 = a.familyMember.relationship;
      const relation2 = b.familyMember.relationship;
      const date1: any = new Date(a.familyMember.dob);
      const date2: any = new Date(b.familyMember.dob);
      const relation_comparison = seccessor_order_ja.indexOf(relation1) - seccessor_order_ja.indexOf(relation2);
      if (relation_comparison !== 0) {
        return relation_comparison;
      }
      return date1 - date2;
    });

    this.sortedList = memberlist_ja;
    const setVal = new Set();
    // filtering registered and laps policy member only
    this.registered_members = this.sortedList.filter((member: any) => member.familyRegistrationStatus == "Registered" || member.familyRegistrationStatus == "Procedure Not Possible"
    );
    //filtering distinct members based on accountID
    this.sorted_distinct_list = this.registered_members.filter((member:any)=> {
      const hasValue = setVal.has(member.familyMember.accountID);
      setVal.add(member.familyMember.accountID);
      return !hasValue;
    })
    this.check_registed_member = memberlist_ja.every((el: { familyMember: string; }) => el.familyMember == null);
  }

  onChange($event: any, value: any) {
    const newValue = value;
    this.isChecked = $event.target.checked;

    if (this.isChecked === true) {
      this.selected_member = newValue;
    }
    this.familyResigstarionService.selectedMember = this.selected_member;
    this.familyResigstarionService.inputPageFormData = null;
    this.familyResigstarionService.accountId = this.selected_member.accountId;
    this.familyResigstarionService.policyHolderName = this.selected_member.policyHolderNameKana;
  }

  /* function to redirect user on cws */

  redirectToCWS() {
    this.cwsRedirectService.redirectToCWSBack();
  }

  prevPage() {
    this.router.navigate(['/family-registration/select-policy']);
  }
}
