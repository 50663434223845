import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FamilyRegistrationComponent } from './family-registration/family-registration.component';
import { SelectPolicyComponent } from './select-policy/select-policy.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { SelectMemberComponent } from './select-member/select-member.component';
import { InputPageComponent } from './input-page/input-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompletionPageComponent } from './completion-page/completion-page.component';
import { HyphenAfterThirdDigitDirective } from '../shared/directive/hyphen-after-third-digit.directive';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import * as _moment from 'moment';
import { DateInputFormatDirective } from '../shared/directive/date-input-format.directive';
import { CustomDateFormatPipe } from '../shared/pipe/custom-date-format.pipe';
import { OnlyNumbersDirective } from '../shared/directive/only-numbers.directive';
import { JapaneseInputDirective } from '../shared/directive/japanese-input.directive';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

@NgModule({
  declarations: [
    FamilyRegistrationComponent,
    SelectPolicyComponent,
    SelectMemberComponent,
    InputPageComponent,
    CompletionPageComponent,
    ConfirmationPageComponent,
    HyphenAfterThirdDigitDirective,
    DateInputFormatDirective,
    CustomDateFormatPipe,
    OnlyNumbersDirective,
    JapaneseInputDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    RegistrationRoutingModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatMomentDateModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'ja'
    })
  ],
  providers: [
    TranslatePipe,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ]
})
export class RegistrationModule { }
