import { Currency, FundCollection } from "./models/fund-details-model";

export const fundDetails: FundCollection = [
  {
    "currentFundCode": 681,
    "currentFundName": "WorldBalanceTypeI(USD)",
    "selectableFundCode": 682,
    "selectableFundName": "JP-USEquityEnhancedInvestment(USD)",
    "currency": Currency.USD,
    "hyperlink": "https://www.manulife.co.jp/ja/individual/funds.html/funddetails/WB-Type1-USD-GASA-Hybrid-DB?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA"
  },
  {
    "currentFundCode": 682,
    "currentFundName": "JP-USEquityEnhancedInvestment(USD)",
    "selectableFundCode": 681,
    "selectableFundName": "WorldBalanceTypeI(USD)",
    "currency": Currency.USD,
    "hyperlink": "https://www.manulife.co.jp/ja/individual/funds.html/funddetails/JP-US-Eq-Enh-USD-GASA-Hybrid-DB?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA"
  },
  {
    "currentFundCode": 684,
    "currentFundName": "WorldBalanceTypeI(AUD)",
    "selectableFundCode": 685,
    "selectableFundName": "JP-USEquityEnhancedInvestment(AUD)",
    "currency": Currency.AUD,
    "hyperlink": "https://www.manulife.co.jp/ja/individual/funds.html/funddetails/WB-Type1-AUD-GASA-Hybrid-DB?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA"
  },
  {
    "currentFundCode": 685,
    "currentFundName": "JP-USEquityEnhancedInvestment(AUD)",
    "selectableFundCode": 684,
    "selectableFundName": "WorldBalanceTypeI(AUD)",
    "currency": Currency.AUD,
    "hyperlink": "https://www.manulife.co.jp/ja/individual/funds.html/funddetails/JP-US-Eq-Enh-AUD-GASA-Hybrid-DB?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA"
  }
]

export const referenceTabledataSource = [
  {
    key: "gasa.referenceDate",
    value: "2024年3月31日"
  },
  {
    key: "gasa.referenceAmount",
    value: "1,234,567.89 米ドル"
  },
  {
    key: "gasa.remainingNumberCommisionfree",
    value: "0 回"
  }]

export const switchingChangesTableDataSource = [
  {
    key: "gasa.confirmation.currentAccount",
    value: "世界バランスⅠ型（米ドル<"
  },
  {
    key: "gasa.confirmation.relocationAccount",
    value: "日米株式エンハンスト運用型（米ドル"
  }]

export const issueDate1 = new Date("2022-3-31");
export const issueDate2 = new Date("2023-4-1");
export const hyperLink1 = "https://www.manulife.co.jp/ja/policyholder/product-info/hayawakari/tanoshimusyushin-hayawakari-03.html#sec08";
export const hyperLink2 = "https://www.manulife.co.jp/ja/policyholder/product-info/hayawakari/tanoshimusyushin-hayawakari-02.html#sec08";
export const hyperLink3 = "https://www.manulife.co.jp/ja/policyholder/product-info/hayawakari/tanoshimusyushin-hayawakari-01.html#sec08";
export const hyperLink4 = "https://www.manulife.co.jp/ja/individual/funds.html/?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA";

export enum SessionData {
  CwsId = "cwsId",
  PolNo = "polNo",
  SprodCd = "sprodCd",
  LprodCd = "lprodCd",
  GasaBackUrl = "gasaBackUrl",
  FreeSwitchesRemaining = "freeSwitchesRemaining",
  CurrentFund = "currentFund",
  ToBeFund = "toBeFund",
  Id = "id"
}
export const benefitAmountTableDataSource = [
  {
    key: "gasa.policyDetails.benefitAmountInfo.info",
    value: "被保険者が死亡した日の基本保険金額、積立金額、または解約返戻金額のいずれか大きい額"
  }]

export const CURRENCY_OPTS: {[key:string]:string} = {
   'JPY': '円',
   'USD': '米ドル'
  }

export const TIME_RANGE_OPTS: {[key:string]:string} = {
  '1m': 'P1M',
  '3m': 'P3M',
  '6m': 'P6M',
  '1y': 'P1Y',
  '2y': 'P2Y'
}

export const STATUS_DESC: {[key:string]:string} = {
  "1": "契約継続中",
  "5": "年金手続未了",
  "6": "年金支払中",
  "8": "－",
  "10": "－",
}

export const RIDER_D_TYPE: {[key:string]:string} = {
  "0": "付加なし",
  "N": "付加なし",
  "1": "付加あり",
  "Y": "付加あり"
}

export const RIDER_FLAG: {[key:string]:string} = {
  "0": "付加なし",
  "N": "付加なし",
  "無": "付加なし",
  "1": "付加あり",
  "Y": "付加あり",
  "有": "付加あり"
}


export enum procedureRequestStatus {
  CWS001 = "CWSACC001",  // We cannot accept requests made within 2 business days of the date the company received your previous switching request.
  CWS002 = "CWSACC002",  // As we have already accepted cancellation procedures, we cannot accept switching procedures.
  CWS003 = "CWSACC003"   // We cannot accept switching procedures as there are similar procedures that have recently been completed.
}
export const CURRENCIES: {[key:string]:string} = {
  JPY: "円",
  USD: "米ドル",
  AUD: "豪ドル",
  NZD: "ニュージーランドドル",
  EUR: "ユーロ"
}

export const CURRENCY_MAPPED: {[key:string]:string} = {
  "¥": "JPY",
  "円": "JPY",
  "米ドル": "USD",
  "豪ドル": "AUD",
  "ニュージーランドドル": "NZD",
  "ユーロ": "EUR"
}

export const TOOLTIP_KEYS: {[key:string]:string} = {
  "tooltipForCashAmt": "gasa.policyDetails.graphHome.tooltipForCashAmt",
  "tooltipForCancelAmt": "gasa.policyDetails.graphHome.tooltipForCancelAmt",
  "tooltipForCurrentAmt": "gasa.policyDetails.fundInfo.tooltipForCurrentAmt",
  "tooltipForSurrenderAmt": "gasa.policyDetails.fundInfo.tooltipForSurrenderAmt",
  "tooltipForCummulativeAmt": "gasa.policyDetails.fundInfo.tooltipForCummulativeAmt"
}

export const GENDER_FLAG: {[key:string]:string}  = {
  "1": "男性",
  "2": "女性",
  "3": "法人",
}

export const DATE_LABELS: {[key:string]:string} = {
  "year": "年",
  "month": "月",
  "day": "日",
  "daytime": "時点",
}

export enum ClientTypes {
  "PolicyHolder" = 1,
  "InsuredHolder" = 2
}

export const FUND_TARGET_VALUE = {
  code: "904",
  ratio: 100
}

export const FAQ_REF_LINKS = {
  faqs: "https://manulife.my.site.com/faq/s/article/お客様専用マイページ-マイページでの契約内容の確認-操作-202403291020",
  marketVal: "https://manulife.my.site.com/faq/s/article/保障内容の変更-解約-解約-1690456655390",
  surrenderVal: "https://manulife.my.site.com/faq/s/article/保障内容の変更-解約-解約-202403290935",
  conversion: "https://www.manulife.co.jp/ja/individual/funds.html/?product=%E6%9C%AA%E6%9D%A5%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E7%B5%82%E8%BA%AB%E4%BF%9D%E9%99%BA"
}

export enum PolicyTypes {
  "Target" = "targetType",
  "Withdrawal" = "withdrawalType"
}

export const POLICY_TYPES_MAPPING: { [key: string]: string } = {
  "BHBTG1": PolicyTypes.Target,
  "PHBTG1": PolicyTypes.Target,
  "BSMTG1": PolicyTypes.Target,
  "BHBWD1": PolicyTypes.Withdrawal,
  "PHBWD1": PolicyTypes.Withdrawal,
  "BSMWD1": PolicyTypes.Withdrawal,
}

export enum WlTransferRider {
  "Wl0" = "付加なし",
  "Wl1" = "付加あり",
}

export const DEFAULT_TARGET_VAL = "設定なし";

export const GRAPH_COLOR = {
  variableAmt: "#76b0ff",
  cashValue: "#76b0ff",
  fixedAmt: "#5cd790",
  cashSurrenderAmt: "#2f364f",
  premiumAmt: "#d17e20",
  targetAmt: "#a3161f",
  transparent: "#ffffff00"
}

export const POLICY_CURRENCY: { [key: string]: string } = {
  "680": CURRENCIES['USD'],
  "681": CURRENCIES['USD'],
  "682": CURRENCIES['USD'],
  "683": CURRENCIES['AUD'],
  "684": CURRENCIES['AUD'],
  "685": CURRENCIES['AUD'],
}

export const SYMBOLS = {
  yen: "年",
  percent: "%"
}

export const SUMITB_PLAN_CODES = {
  code1: "BSMTG1",
  code2: "BSMWD1"
}

export const ISSUE_DATE_LIMITS = {
  upperLimit: '2023-04-01',
  midLimit: '2023-03-31',
  lowerLimit: '2022-04-01',
}

export const SPECIAL_ACC_VAL = {
  finalCode: 'NA',
  finalValue: '（ご契約時）'
}

// labels for series data in fund graph
export enum FundGraphSeriesLabels {
  FundVariable = "Fund Variable",
  CashValue = "Cash Value",
  FundFixed = "Fund Fixed",
  FundSurrender = "Fund Surrender",
  FundPremium = "Fund Premium",
  TargetAmount = "Target Amount",
  SaAmountPercentageChange = "sa Amount Percentage Change",
  GaAmountPercentageChange = "ga Amount Percentage Change",
  CashSurrenderValuePercentageChange = "cash Surrender Value Percentage Change",
  FundName = "fund Name"
}

/* STARTOF constants related to GasaAdobeAnalytics module */
export const TRACK_LINK_TYPE = {
  internal: "INTERNAL",
  external: "EXTERNAL"
}

export const TRACK_FUND_SWITCH = {
  start: "START",
  complete: "COMPLETE",
  cancel: "CANCEL"
}

export const TRACK_LINK_LABELS = {
  fundSwitchLeftMenu: "mfjp:policydetailpage:policyinquiry-GASA用-Leftmenu-Button積立金移転",
  fundSwitch: "mfjp:policydetailpage:policyuinquiry-GASA用-契約照会-Button積立金移転手続き",
  policyInfo: "商品内容に関するご説明",
  faq: "よくあるご質問",
  marketAdjust: "mfjp:policydetailpage:policyinquiry-GASA用-契約照会-LinkFAQ市場価格調整②",
  surrenderDeduct: "mfjp:policydetailpage:policyinquiry-GASA用-契約照会-LinkFAQ解約控除③",
  conversionBtn: "mfjp:policydetailpage:policyinquiry-GASA用-契約照会-Button特別勘定の騰落率（一覧）",
  specialAccounts: "mfjp:policydetailpage:switching-GASA用-注意事項-LinkPWS早わかりガイド特別勘定",
  trends: "mfjp:policydetailpage:switching-GASA用-注意事項-LinkPWS騰落率（一覧）",
  fundName: "mfjp:policydetailpage:switching-GASA用-入力フォーム-LinkPWS騰落率（個別）",
}

export const TRACK_BUTTON_LABELS = {
  faqBtn: "よくあるご質問",
  conversionBtn: "mfjp:policydetailpage:policyinquiry-GASA用-契約照会-Button特別勘定の騰落率（一覧）",
  fundSwitch: "mfjp:policydetailpage:policyuinquiry-GASA用-契約照会-Button積立金移転手続き",
  cancelBtn: "キャンセル",
  proceedBtn: "次のステップへ進む",
  returnToPrevious: "前の画面へ戻る",
  switchConfirm: "mfjp:policydetailpage:switching-GASA用-確認ページ-Button確定"
}

export const TRACK_PAGE_NAME = {
  policyDetailsPage: "mfjp:policydetailpage:policyinquiry-GASA用",
  disclaimerPage: "mfjp:policydetailpage:switching-GASA用-注意事項",
  switchingPage: "mfjp:policydetailpage:switching-GASA用-入力フォーム",
  confirmationPage: "mfjp:policydetailpage:switching-GASA用-確認ページ"
}

export const TRACK_POPUP_TITLE = {
  errorCWS001: "mfjp:policydetailpage:Switching GASA用-共通-PopupERR001-2営業日以内手続き",
  errorCWS002: "mfjp:policydetailpage:Switching GASA用-共通-PopupERR002-解約済",
  errorCWS003: "mfjp:policydetailpage:Switching GASA用-共通-PopupERR003-未完了同一手続き"
}
/* ENDOF constants related to GasaAdobeAnalytics module */