import { PlatformLocation } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, take } from 'rxjs';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LoaderService } from 'src/app/service/loader.service';
import { common_urls } from 'src/environments/environment';
import { InputPageComponent } from '../input-page/input-page.component';
import { methodOfConfirmationOfConsent, errorMessageConfirmation } from 'src/app/shared/constants-c360';
@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit, OnDestroy {
  queryParamsSubscription!: Subscription;
  pageTitle: string = '';
  myResponseList: any;
  atBottom = true;
  @Output() scroll = new EventEmitter<string>();
  confirmForm!: FormGroup;
  registerForm!: FormGroup;
  methodOfConfirmationOfConsent = methodOfConfirmationOfConsent;
  addressResponseList: any;
  showConsern: any;
  payload: any;
  selectValue: Boolean = false;
  policylist: any[] = [];
  checkerror: any;
  dobModified: any;
  selectPolicyDetail: any;
  responseErrMessage: any;
  terms_and_conditions_error_msg : any;
  numberStr: any;
  @ViewChild(InputPageComponent) InputPageComponent!: InputPageComponent;
  methodOfConError: boolean = false;
  constructor(private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
    private inactivityService: InactivityService, private loaderService: LoaderService,
    private translateService: TranslateService, private titleService: Title,
    private cwsRedirectService: CwsRedirectService, private familyResigstarionService: FamilyRegistrationService, private router: Router, platformLocation: PlatformLocation) {

  }

  ngOnInit(): void {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.loaderService.hide();
    this.inactivityService.startTrackingInactivity();
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {

    });
    this.getAccIDDetails();
    this.getPolicyData();
    this.initializeFormData();
    this.initializeForm();
    this.dataPopulateValue();
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) this.queryParamsSubscription.unsubscribe();
  }
  getAccIDDetails() {
    this.selectPolicyDetail = this.familyResigstarionService.transferData;
  }

  getPolicyData() {
    if (this.familyResigstarionService.selectedMember != null) {
      this.myResponseList = this.familyResigstarionService.selectedMember;
      this.showConsern = true;
    }
    else {
      this.myResponseList = this.familyResigstarionService.inputPageFormData;
      if (this.myResponseList?.dob != null) {

        this.dobModified = this.myResponseList?.dob;
      }
      this.showConsern = false;
    }
  }

  /**populate*/
  dataPopulateValue() {
    if (this.familyResigstarionService.selectedMember != null) {
      this.confirmForm.controls['methodOfConfirmationOfConsent'].setValue(this.myResponseList?.familyMember?.methodOfConfirmation);
    }
    else {

      const telFirst = this.myResponseList.telephoneFieldOne;
      const telSecond = this.myResponseList.telephoneFieldTwo;
      const telThird = this.myResponseList.telephoneFieldThree;
      let phoneNumberNewValue = telFirst + "-" + telSecond + "-" + telThird;
      let genderValue;
      if(this.myResponseList?.gender == '男')
      {
        genderValue ='男性';
      }
      else{
        genderValue = '女性';
      }


      this.registerForm.controls['methodOfConfirmation'].setValue(this.myResponseList?.methodOfConfirmation);
      this.registerForm.controls['relationship'].setValue(this.myResponseList?.relationship);
      this.registerForm.controls['kanjiFirstName'].setValue(this.myResponseList?.kanjiFirstName);
      this.registerForm.controls['kanjiLastName'].setValue(this.myResponseList?.kanjiLastName);
      this.registerForm.controls['kanaFirstName'].setValue(this.myResponseList?.kanaFirstName);
      this.registerForm.controls['kanaLastName'].setValue(this.myResponseList?.kanaLastName);
      this.registerForm.controls['gender'].setValue(genderValue);
      this.registerForm.controls['dateOfBirth'].setValue(this.dobModified);
      this.registerForm.controls['zipCode'].setValue(this.myResponseList?.zipCode);
      this.registerForm.controls['firstAddress'].setValue(this.myResponseList?.addressAutofilled?.addressKanji);
      this.registerForm.controls['secondAddress'].setValue(this.myResponseList?.addressContinued);
      this.registerForm.controls['telephoneFieldOne'].setValue(this.myResponseList?.telephoneFieldOne);
      this.registerForm.controls['telephoneFieldTwo'].setValue(this.myResponseList?.telephoneFieldTwo);
      this.registerForm.controls['telephoneFieldThree'].setValue(this.myResponseList?.telephoneFieldThree);
      this.registerForm.controls['telephone'].setValue(phoneNumberNewValue);
      this.disableData();
    }
  }

  /*Disable function*/
  disableData() {
    this.registerForm.controls['methodOfConfirmation'].disable();
    this.registerForm.controls['relationship'].disable();
    this.registerForm.controls['kanjiFirstName'].disable();
    this.registerForm.controls['kanjiLastName'].disable();
    this.registerForm.controls['kanaFirstName'].disable();
    this.registerForm.controls['kanaLastName'].disable();
    this.registerForm.controls['gender'].disable();
    this.registerForm.controls['dateOfBirth'].disable();
    this.registerForm.controls['zipCode'].disable();
    this.registerForm.controls['firstAddress'].disable();
    this.registerForm.controls['secondAddress'].disable();
    this.registerForm.controls['telephoneFieldOne'].disable();
    this.registerForm.controls['telephoneFieldTwo'].disable();
    this.registerForm.controls['telephoneFieldThree'].disable();
    this.registerForm.controls['telephone'].disable();
  }

  onChange($event: any) {
    const isChecked = $event.target.checked;

    if (isChecked === true) {
      this.selectValue = true;
    } else {
      this.selectValue = false;
    }
  }

  /*post function */

  postFunction() {
  if(this.selectValue == true){
    this.policylist = [];
    this.selectPolicyDetail?.forEach((el: any) => {
      this.policylist.push(el.policyID);
    })
  }else{
    this.policylist = [];
  }

    if (this.familyResigstarionService.selectedMember != null) {
      /*hypen remove for zipcode and telephone*/
      let zipCodeNewValue = this.myResponseList.familyMember.address?.zipCode;
      zipCodeNewValue = zipCodeNewValue.replace(/-/g, '');

      let phoneNumberNewValue = this.myResponseList.familyMember?.phoneNumber;
      if (phoneNumberNewValue != null) {

        phoneNumberNewValue = phoneNumberNewValue.replace(/-/g, '');
      }

      let addressCode = this.myResponseList.familyMember.address?.addrCd;

      this.addressCodeValidation(addressCode);

      this.payload =
      {
        "accountID": this.selectPolicyDetail[0].accountID,
        "firstName": this.selectPolicyDetail[0].policyHolderNameKana,
        "categoryType2": "登録",
        "methodOfConfirmation": this.confirmForm.value.methodOfConfirmationOfConsent,
        "relationship": this.myResponseList.familyMember?.relationship,
        "kanjiFirstName": this.myResponseList.familyMember?.kanjiFirstName,
        "kanjiLastName": this.myResponseList.familyMember?.kanjiLastName,
        "kanaFirstName": this.myResponseList.familyMember?.kanaFirstName,
        "kanaLastName": this.myResponseList.familyMember?.kanaLastName,
        "gender": this.myResponseList.familyMember?.gender,
        "dateOfBirth": this.myResponseList.familyMember?.dob,
        "address": {
          "addressAuto": this.myResponseList.familyMember.address?.addressDesc2,
          "addressContinue": this.myResponseList.familyMember.address?.addressDesc1,
          "zipCode": zipCodeNewValue,
          "addrCd": this.addressCodeValidation(addressCode)
        },
        "phoneNumber": phoneNumberNewValue,
        "listOfPolicyID": this.policylist

      }

    }
    else {
      /*hypen remove for zipcode and telephone*/
      let zipCodeNewValue = this.myResponseList?.zipCode;
      zipCodeNewValue = zipCodeNewValue.replace(/-/g, '');

      const telFirst = this.myResponseList.telephoneFieldOne;
      const telSecond = this.myResponseList.telephoneFieldTwo;
      const telThird = this.myResponseList.telephoneFieldThree;
      let phoneNumberNewValue = telFirst + telSecond + telThird;

      let addressCodeFromInput = this.myResponseList?.addressAutofilled?.addrCd;

      this.payload =
      {
        "accountID": this.selectPolicyDetail[0].accountID,
        "firstName": this.selectPolicyDetail[0].policyHolderNameKana,
        "categoryType2": "登録",
        "methodOfConfirmation": this.myResponseList?.methodOfConfirmation,
        "relationship": this.myResponseList?.relationship,
        "kanjiFirstName": this.myResponseList?.kanjiFirstName,
        "kanjiLastName": this.myResponseList?.kanjiLastName,
        "kanaFirstName": this.myResponseList?.kanaFirstName,
        "kanaLastName": this.myResponseList?.kanaLastName,
        "gender": this.myResponseList?.gender,
        "dateOfBirth": this.dobModified,
        "address": {
          "addressAuto": this.myResponseList?.addressAutofilled?.addressKanji,
          "addressContinue": this.myResponseList?.addressContinued,
          "zipCode": zipCodeNewValue,
          "addrCd": this.addressCodeValidation(addressCodeFromInput)
        },
        "phoneNumber": phoneNumberNewValue,
        "listOfPolicyID": this.policylist
      }
    }
    this.loaderService.show();
    if (this.selectValue == true && this.registerForm.disabled) {

      this.familyResigstarionService.onSubmit(this.payload).subscribe(res => {
        this.loaderService.hide();

      }, error => {
        console.log(error);
        if (error.status === 201 || error.status === 200) {
          this.router.navigate(['/family-registration/completion-page']);
        } else if (error.status === 500 || error.status === 503 || error.status === 504) {
          this.responseErrMessage = errorMessageConfirmation.responseErrMessage.Err_500_503_504;
        } else if (error.status === 400) {
          this.responseErrMessage = errorMessageConfirmation.errMessageBadRequest.Err_400;
        } else if (error.status === 404) {
          this.responseErrMessage = errorMessageConfirmation.errMessageNoRecord.Err_404;
        }
        this.loaderService.hide();
      })

    }
    else if (this.selectValue == true && this.confirmForm.valid && this.familyResigstarionService.selectedMember != null) {

      this.familyResigstarionService.onSubmit(this.payload).subscribe(res => {
        this.loaderService.hide();

      }, error => {
        console.log(error);
        if (error.status === 201 || error.status === 200) {
          this.router.navigate(['/family-registration/completion-page']);
        } else if (error.status === 500 || error.status === 503 || error.status === 504) {
          this.responseErrMessage = errorMessageConfirmation.responseErrMessage.Err_500_503_504;
        } else if (error.status === 400) {
          this.responseErrMessage = errorMessageConfirmation.errMessageBadRequest.Err_400;
        } else if (error.status === 404) {
          this.responseErrMessage = errorMessageConfirmation.errMessageNoRecord.Err_404;
        }
        this.loaderService.hide();
      })

    }
    else {
      if (this.selectValue == false) {
        this.checkerror = true;
        this.terms_and_conditions_error_msg = errorMessageConfirmation.alert;
      }
      else {
        this.methodOfConError = true;
        this.checkerror = false;
      }

      this.loaderService.hide();
    }

  }

  addressCodeValidation(numValue: any, maxLength=8){
    this.numberStr = numValue?.toString();
    while(this.numberStr?.length < maxLength){
      this.numberStr = '0' + this.numberStr;
    }
    return this.numberStr;
  }

  /*Previous page function*/
  backFunction() {
    if (this.familyResigstarionService.selectedMember != null) {
      this.router.navigate(['/family-registration/select-members'], { state: { fromConfirmationPage: true, policyNumber: this.familyResigstarionService.selectedMember.policyNumber } });
    }
    else {
      let data = this.registerForm.value;

      const telFirst = data.telephoneFieldOne;
      const telSecond = data.telephoneFieldTwo;
      const telThird = data.telephoneFieldThree;
      let phoneNumberModifiedValue = telFirst + telSecond + telThird;

      let obj = {
        methodOfConfirmationOfConsent: data.methodOfConfirmation,
        relationship: data.relationship,
        kanjiFirstName: data.kanjiFirstName,
        kanjiLastName: data.kanjiLastName,
        kanaFirstName: data.kanaFirstName,
        kanaLastName: data.kanaLastName,
        gender: data.gender,
        dateOfBirth: this.myResponseList.date_original,
        zipCode: data.zipCode,
        firstAddress: this.myResponseList?.addressAutofilled,
        secondAddress: data.secondAddress,
        telephoneFieldOne: data.telephoneFieldOne,
        telephoneFieldTwo: data.telephoneFieldTwo,
        telephoneFieldThree: data.telephoneFieldThree,
        telephone: phoneNumberModifiedValue
      }
      this.familyResigstarionService.dataFromConfirmationToInput = obj;
      this.familyResigstarionService.dataFromConfirmationPageFlag = true;
      this.router.navigate(['/family-registration/input-page']);

    }

  }

  /*scroll down check */
  checkthis(e: any) {
    if (e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight) {
      this.atBottom = false;
    }
  }


  /* function to redirect user on cws */
  redirectToCWS() {

    this.cwsRedirectService.redirectToCWSBack();
  }

  private initializeFormData(): void {
    this.confirmForm = new FormGroup({
      methodOfConfirmationOfConsent: new FormControl('', Validators.required),

    });
  }
  private initializeForm(): void {
    this.registerForm = new FormGroup({
      methodOfConfirmation: new FormControl('', Validators.required),
      relationship: new FormControl('', Validators.required),
      kanjiFirstName: new FormControl('', Validators.required),
      kanjiLastName: new FormControl('', Validators.required),
      kanaFirstName: new FormControl('', Validators.required),
      kanaLastName: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      dateOfBirth: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      firstAddress: new FormControl('', Validators.required),
      secondAddress: new FormControl('', Validators.required),
      telephoneFieldOne: new FormControl('', Validators.required),
      telephoneFieldTwo: new FormControl('', Validators.required),
      telephoneFieldThree: new FormControl('', Validators.required),
      telephone: new FormControl('', Validators.required)
    });
  }

}
