<div class="container">
    <!-- response error message div shown when API failure -->
    <div>
        <div *ngIf="responseErrMessage">
            <div class="errorMsgRed">
                {{responseErrMessage}}
            </div>
        </div>
    </div>
    <!-- response error message div shown when API failure -->
    <!-- content of the page start-->
    <div *ngIf="!responseErrMessage">
        <h1>{{'family-registration-select-policy.a2.heading' | translate}}</h1>
        <ul class="c-flow">
            <li class="first-breadcrumbs">{{'family-registration-select-policy.a2.breadcrumbs.one' | translate}}</li>
            <li>{{'family-registration-select-policy.a2.breadcrumbs.two' | translate}}</li>
            <li class="is-current">{{'family-registration-select-policy.a2.breadcrumbs.three' | translate}}</li>
            <li>{{'family-registration-select-policy.a2.breadcrumbs.four' | translate}}</li>
        </ul>
        <h2>{{'family-registration-confirmation-page.a3.heading' | translate}}</h2>
        <h3 style="font-weight: 400;" *ngIf="!showConsern">{{'family-registration-confirmation-page.a3.one'| translate}}
        </h3>
        <h3 style="font-weight: 400;" *ngIf="showConsern">{{'family-registration-confirmation-page.a3.four'| translate}}
        </h3>
        <div class="content-wrap">
            <div class="a4-wrap">
                <!-- Data Populating in List from US8 to US10 -->
                <div class="a5-wrap">
                    <h4>{{'family-registration-confirmation-page.a3.two' | translate}}</h4>
                    <div>
                        <ol class="ordered-list-new" *ngFor="let policy of selectPolicyDetail, let i= index">
                            <div class="row">
                                <div class="col-12 label text-font" style="width: 100%;">{{policy.petName}}</div>
                            </div>
                            <div class="row">
                                <div class="card-family">
                                    <div class="field">
                                        <span
                                            class="label">{{'family-registration-confirmation-page.common.policynumberText'
                                            |
                                            translate}}</span>
                                        <span class="valuelab mobile-appearance-number">{{policy.policyNumber}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="card-family">
                                    <div class="field">
                                        <span
                                            class="label">{{'family-registration-confirmation-page.common.insuredPersonNameKanaText'
                                            |
                                            translate}}</span>
                                        <span class="value">{{policy.insuredPersonNameKana}}</span>
                                    </div>
                                </div>
                            </div>
                        </ol>
                    </div>
                    <!-- Consern Option Show/ Hide -->
                    <div *ngIf="showConsern">
                        <h4>{{'family-registration-confirmation-page.a3.three' | translate}}</h4>
                        <form [formGroup]="confirmForm" accept-charset="SHIFT_JIS">
                            <div class="methodOfConsent">
                                <div class="mobile-text-align">
                                    <span>
                                        {{'family-registration-confirmation-page.common.mcc.methodOfConfirmationOfConsent_first_text'| translate}}
                                    </span>
                                    <span class="methodOfConfirmationOfConsent_second_text">
                                        {{'family-registration-confirmation-page.common.mcc.methodOfConfirmationOfConsent_second_text'
                                        | translate}}
                                    </span>
                                </div>
                                <ng-select class="dropdwon" id="methodOfConsentDropdown" [searchable]="true"
                                    formControlName="methodOfConfirmationOfConsent">
                                    <ng-option *ngFor="let option of methodOfConfirmationOfConsent"
                                        [value]="option.dataValue">
                                        {{ option.dataValue }}
                                    </ng-option>
                                </ng-select>
                                <div class="error-section"
                                    *ngIf="methodOfConError && confirmForm.controls['methodOfConfirmationOfConsent'].errors?.['required']">
                                    <span class="error">
                                        {{'family-registration-input-page.page-text.validation_err.invalidmsg' |
                                        translate}}</span>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <h4>
                                <div class="name-align-address">
                                    <div class="name-align-address-content">
                                        <span class="name-align">{{myResponseList.familyMember?.kanjiLastName}}</span>
                                        <span class="name-align">{{myResponseList.familyMember?.kanjiFirstName}}</span>
                                    </div>
                                </div>
                            </h4>
                        </div>

                        <div class="main-list">
                            <ol class="ordered-list" *ngIf="myResponseList">
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.kanaNameText' | translate}}
                                            </span>
                                            <span class="value">
                                                <span class="name-align">
                                                    {{myResponseList.familyMember?.kanaLastName}}
                                                </span>
                                                <span class="name-align">
                                                    {{myResponseList.familyMember?.kanaFirstName}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>    
                                <div class="row">
                                    <div class="card-family" style="margin-top: 10px;">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.dateOfBirthText' | translate}}</span>
                                            <span class="value">{{myResponseList.familyMember?.dob | customDateFormat}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.genderText' | translate}}</span>
                                            <span *ngIf="myResponseList.familyMember?.gender == '男'" class="value">男性</span>
                                            <span *ngIf="myResponseList.familyMember?.gender == '女'" class="value">女性</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.relationshipText' | translate}}</span>
                                            <span class="value">{{myResponseList.familyMember?.relationship}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.addressText' | translate}}</span>
                                            <span class="value"> {{'〒'+ myResponseList.familyMember?.address.zipCode}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label"></span>
                                            <span class="value">{{myResponseList.familyMember?.address.addressDesc2}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label"></span>
                                            <span class="value address-wrap name-align-address-second">
                                                <span class="name-align-address-second-content">
                                                    {{myResponseList.familyMember?.address.addressDesc1}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card-family">
                                        <div class="field">
                                            <span class="label">{{'family-registration-confirmation-page.fromselectpolicy.phoneNumberText' | translate}}</span>
                                            <span class="value mobile-appearance-number">{{myResponseList.familyMember?.phoneNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ol>
                        </div>

                        
                    </div>
                    <!-- Consern Option Show/ Hide -->
                </div>
                <!-- Data Populating in List from US8 to US10 -->

                <div class="a5-wrap" *ngIf="!showConsern">
                    <!-- Data Populating in List from US9 to US10 -->
                        <h4>{{'family-registration-confirmation-page.a3.three' | translate}}</h4>
                        <form [formGroup]="registerForm" accept-charset="SHIFT_JIS">
                            <div>
                                <div>&nbsp;</div>
                                <div class="methodOfConsent">
                                    <div class="mobile-text-align">
                                        <span>
                                            {{'family-registration-confirmation-page.fromInputPage.mcc.methodOfConfirmationOfConsent_first_text'
                                            | translate}}
                                        </span>
                                        <span class="methodOfConfirmationOfConsent_second_text">
                                            {{'family-registration-confirmation-page.fromInputPage.mcc.methodOfConfirmationOfConsent_second_text'
                                            | translate}}
                                        </span>
                                    </div>

                                    <input type="text" id="consent" class="form-control" value=""
                                        formControlName="methodOfConfirmation" maxlength="80">
                                </div>
                                <div>&nbsp;</div>
                                <div class="relationship">
                                    <div class="mobile-text-align">
                                        <span>
                                            {{'family-registration-confirmation-page.fromInputPage.relationship.relationship_first_text'
                                            |
                                            translate}}
                                        </span>
                                        <span class="relationship_second_text">
                                            {{'family-registration-confirmation-page.fromInputPage.relationship.relationship_second_text'
                                            |
                                            translate}}
                                        </span>
                                    </div>

                                    <input type="text" id="relation" class="form-control" value=""
                                        formControlName="relationship" maxlength="80">
                                </div>
                                <div>&nbsp;</div>
                                <div class="row row-kanji" style="display: flex;">
                                    <div class="kanaName">
                                        <div class="kanjiNameLabel">
                                            {{'family-registration-confirmation-page.fromInputPage.kanji.firstName'
                                            |
                                            translate}}</div>
                                        <input type="text" id="lastName" class="form-control" value=""
                                            formControlName="kanjiLastName" maxlength="80">
                                    </div>
                                    <div class="kanaName">
                                        <div class="kanjiNameLabel">
                                            {{'family-registration-confirmation-page.fromInputPage.kanji.lastName'
                                            |
                                            translate}}</div>
                                        <input type="text" id="familyName" class="form-control" value=""
                                            formControlName="kanjiFirstName" maxlength="80">
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <div class="row row-kana" style="display: flex;">
                                    <div class="kanaName">
                                        <div class="kanaNameLabel">
                                            {{'family-registration-confirmation-page.fromInputPage.kana.firstName' |
                                            translate}}</div>
                                        <input type="text" id="lastName" class="form-control" value=""
                                            formControlName="kanaLastName" maxlength="30">
                                    </div>
                                    <div class="kanaName">
                                        <div class="kanaNameLabel">
                                            {{'family-registration-confirmation-page.fromInputPage.kana.lastName' |
                                            translate}}</div>
                                        <input type="text" id="familyName" class="form-control" value=""
                                            formControlName="kanaFirstName" maxlength="30">
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <div class="gender common-align">
                                    <div> {{'family-registration-confirmation-page.fromInputPage.gender.genderText' |
                                        translate}}
                                    </div>

                                    <input type="text" id="genderName" class="form-control" value=""
                                        formControlName="gender" maxlength="80">
                                </div>
                                <div>&nbsp;</div>
                                <div class="date-picker common-align">
                                    <div class="date-picker-label">
                                        <span class="labels mobile-text-align">
                                            <span>
                                                {{'family-registration-confirmation-page.fromInputPage.DOB.date_of_birth_first_text'
                                                | translate}}</span>
                                            <span>
                                                {{'family-registration-confirmation-page.fromInputPage.DOB.date_of_birth_second_text'
                                                | translate}}</span>
                                        </span>
                                    </div>
                                    <div class="date-picker-feild">
                                        <input type="text" id="date" class="form-control" value=""
                                            formControlName="dateOfBirth" maxlength="80">
                                    </div>

                                </div>
                                <div>&nbsp;</div>
                                <div class="common-align">
                                    <div> {{'family-registration-confirmation-page.fromInputPage.zipCode.zipCodeText' |
                                        translate}}
                                    </div>
                                    <input type="text" id="zipcode" class="form-control" value=""
                                        formControlName="zipCode" maxlength="7">

                                </div>
                                <div>&nbsp;</div>
                                <div class="address common-align">
                                    <div>
                                        {{'family-registration-confirmation-page.fromInputPage.firstAddress.firstAddressText'
                                        |
                                        translate}}</div>

                                    <input type="text" id="tel" class="form-control" value=""
                                        formControlName="firstAddress" maxlength="11">
                                </div>
                                <div>&nbsp;</div>
                                <div class="common-align">
                                    <div>
                                        {{'family-registration-confirmation-page.fromInputPage.secondAddress.secondAddressTextNew'
                                        |
                                        translate}}</div>
                                    <textarea type="text" id="secondaddress" class="form-control text-area" value=""
                                        formControlName="secondAddress" maxlength="255">
                                        </textarea>
                                </div>
                                <div>&nbsp;</div>
                                <div class="date-picker common-align">
                                    <div class="date-picker-label">
                                        <div class="mobile-text-align">
                                            <span>
                                                {{'family-registration-confirmation-page.fromInputPage.telephone.telephoneTextNew'
                                                |
                                                translate}}</span>
                                            <!-- <span>
                                                {{'family-registration-confirmation-page.fromInputPage.telephone.telephoneText2'
                                                |
                                                translate}}</span> -->
                                        </div>
                                    </div>
                                    <div class="phone-number-feild">
                                        <div class="first-box hide-element">
                                            <div>
                                                <input id="firstInput" class="form-control"
                                                    formControlName="telephoneFieldOne" />
                                            </div>
                                        </div>
                                        <span class="hyphen-align hide-element">-</span>
                                        <div class="second-box hide-element">
                                            <div>
                                                <input id="secondInput" class="form-control"
                                                    formControlName="telephoneFieldTwo" />
                                            </div>
                                        </div>
                                        <span class="hyphen-align hide-element">-</span>
                                        <div class="third-box hide-element">
                                            <div>
                                                <input id="thirdInput" class="form-control"
                                                    formControlName="telephoneFieldThree" />
                                            </div>
                                        </div>
                                        <div style="width: 100%">
                                            <input id="telephoneInput" class="form-control"
                                                formControlName="telephone" />
                                        </div>
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                        </form>
                    <!-- Data Populating in List from US9 to US10 -->
<div>&nbsp;</div>
                    <!-- Data Populating in List from US9 to US10 -->

                </div>

                <div class="family-info-section">
                    <div>
                        <h3 class="h3-familyinfo">{{'family-registration-confirmation-page.a4.familyinfo.heading' |
                            translate}}</h3>


                        <div style="height: 190px; overflow: auto; border: 1px solid;padding: 10px;"
                            (scroll)="checkthis($event)">
                            <div class="content-wrap">

                                <p>{{'family-registration-confirmation-page.a4.familyinfo.one' | translate}}</p>
                                <p>{{'family-registration-confirmation-page.a4.familyinfo.two' | translate}} <span class="a2-link-parent"><a
                                    href="https://www.manulife.co.jp/ja/individual/about/company/privacypolicy.html"
                                    target="_blank"
                                    class="a2-link">{{'family-registration-confirmation-page.a4.familyinfo.three'
                                    |
                                    translate}}</a><span class="a2-link-child"></span></span>{{'family-registration-confirmation-page.a4.familyinfo.four'
                                    | translate}}
                                </p>
                                <div>
                                    <ol class="main-content-list">
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.1' | translate}}
                                        </li>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.2.a' |
                                            translate}}</li>
                                        <p>{{'family-registration-confirmation-page.a4.familyinfo.2.b' |
                                            translate}}</p>
                                        <p>{{'family-registration-confirmation-page.a4.familyinfo.2.c' |
                                            translate}}</p>
                                        <ol class="sub-content-list" type="A">
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.2.d' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.2.e' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.2.f' |
                                                translate}}</li>
                                        </ol>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.3' | translate}}
                                        </li>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.4' | translate}}
                                        </li>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.5.a' |
                                            translate}}</li>
                                            <p>{{'family-registration-confirmation-page.a4.familyinfo.5.b'
                                                | translate}}</p>
                                        <ol class="sub-content-list" type="A">
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.c' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.d' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.e' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.f' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.g' |
                                                translate}}</li>
                                            <li>
                                                {{'family-registration-confirmation-page.a4.familyinfo.5.h' |
                                                translate}}</li>
                                        </ol>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.6' | translate}}
                                        </li>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.7' | translate}}
                                        </li>
                                        <li>{{'family-registration-confirmation-page.a4.familyinfo.8' | translate}}
                                        </li>
                                    </ol>
                                </div>


                            </div>
                        </div>
                        <div class="a2-wrap">
                            <div class="btn-wrap">

                                <div class="row">
                                    <div class="card-family">
                                        <!-- <div class="field">
                                            <input (change)="onChange($event)" [disabled]="atBottom"
                                                type="checkbox" />
                                            <span
                                                class="value"></span>
                                        </div> -->
                                        <div class="form-group">
                                            <input type="checkbox" id="confirmation-checkbox"
                                                (change)="onChange($event)" [disabled]="atBottom">
                                            <label class="checkbox-text"
                                                for="confirmation-checkbox">{{'family-registration-confirmation-page.a2.checkbox'
                                                | translate}}</label>
                                        </div>
                                        <div class="error-section" *ngIf="checkerror">
                                            <span class="error">
                                                {{terms_and_conditions_error_msg}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="a2-wrap">
                    <div class="btn-wrap">
                        <button type="button" mat-flat-button color="accent" class="back-custom-btn-primary"
                            (click)="backFunction()">{{'family-registration-confirmation-page.a2.secondaryBtnText' |
                            translate}}</button>
                        <button mat-flat-button type="submit" color="primary" class="custom-btn-primary"
                            (click)="postFunction()">{{'family-registration-confirmation-page.a2.primaryBtnText' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content of the page end-->
</div>