import { Component, OnDestroy, OnInit, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SimplifiedCwsRegistrationService } from '../../service/simplified-cws-registration.service'
import { DateAdapter } from "@angular/material/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { GlobalSettings } from 'src/app/shared/GlobalSetting';
import { CustomeValidator } from 'src/app/shared/CustomeValidator';
import { MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as CryptoJS from 'crypto-js';
import { LoaderService } from 'src/app/service/loader.service';
import { common_url_sur } from 'src/environments/environment';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { DatePipe } from '@angular/common';
import { hasNumber, hasKannaChar, hasKanjiChar, hasAddressChar, methodOfConfirmationOfConsent, memberRelationshipInput, memberGender, errorMessageInput, zeroNum } from 'src/app/shared/constants-c360';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-input-page',
  templateUrl: './input-page.component.html',
  styleUrls: ['./input-page.component.scss']
})
export class InputPageComponent implements OnInit, OnDestroy, AfterViewInit {
  registrationForm!: FormGroup;
  cwsqueryParamsSubscription!: Subscription;
  token: any;
  terms1: boolean = false;
  terms2: boolean = false;
  simplifiedCWSRegSubscription!: Subscription
  isValid: boolean = true
  showFormError: boolean = false;
  showPasswordError: boolean = false;
  formErrMessage: string = '';
  passwordErrMessage: string = '';
  submitFormSubscription!: Subscription;
  responseMessage: string = '';
  disabledCheck = true;
  disabledCheckBox1 = true;
  disabledCheckBox2 = true;
  disabledCheckBox3 = true;
  disabledCheckBox4 = true;
  Yearlist: Array<Number> = [];
  zipCodeNewValue: any;
  MonthList = ['01', '02', '03', '04', '05', '06', '07', '08', '12', '10', '11', '12'];
  DayList: Array<any> = [];
  today = new Date();
  isCampaginExpired = false;
  emailId: string = '';
  defaultYear = '1980';
  errorValue: string = "";
  memberdetail: any;
  account_id: any;
  insured_PersonName_Kana: any;
  policy_num: any;
  pet_name: any;
  responseErrMessage: any;
  methodOfConfirmationOfConsent = methodOfConfirmationOfConsent;
  memberRelationship = memberRelationshipInput;
  memberGender = memberGender;
  date1: Date | null | undefined;
  addressResponseList: any;
  numberValue: any;
  zipCodeErrMessage: string = "";
  showError: boolean = false;
  savedData: any;
  phoneNumberCheckValidation: boolean = false;
  newConcatenatedValue: any;
  pageTitle: string = '';
  addressAutoWholeObj: any;
  constructor(private dateAdapter: DateAdapter<any>,
    private route: ActivatedRoute,
    public simplifiedCwsRegistrationService: SimplifiedCwsRegistrationService,
    private router: Router,
    private loader: LoaderService,
    private renderer: Renderer2,
    private el: ElementRef,
    private familyResigstarionService: FamilyRegistrationService,
    public datepipe: DatePipe, private translateService: TranslateService, private titleService: Title
  ) {
    this.dateAdapter.setLocale(GlobalSettings.CURRENT_LOCALE);
  }
  show = false;
  ngOnInit() {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.cwsqueryParamsSubscription = this.route.queryParams.subscribe(params => {
      if (params) {
        this.simplifiedCwsRegistrationService.setToken(params);
      }
    })
    this.initializeForm();
    this.createDayList();
    this.createYearList();


    if (this.familyResigstarionService.dataFromConfirmationPageFlag) {
      const relationship = this.registrationForm.controls['relationship'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.relationship);

      this.registrationForm.controls['dateOfBirth'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.dateOfBirth);
      this.registrationForm.controls['relationship'].setValue(relationship);
      this.setValueForForm();
    }
  }

  ngAfterViewInit(): void {
    const dpSVGIcon = this.el.nativeElement.querySelector(".mat-mdc-icon-button svg");
    this.renderer.setStyle(dpSVGIcon, 'width', '30px');
    this.renderer.setStyle(dpSVGIcon, 'height', '30px');
  }


  get dateOfBirth() {
    return this.registrationForm.controls['dateOfBirth'].value;
  }

  private ageRange(minAge: number, maxAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const today = new Date();
      const dob = new Date(control.value);
      let age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
        age--;
      }
      if (age < minAge) {
        return age >= minAge ? null : { minAge: { requiredAge: age, actualAge: age } };
      }
      if (age > maxAge) {
        return age <= maxAge ? null : { maxAge: { requiredAge: age, actualAge: age } };
      }
      return null; // Valid if age is between minAge and maxAge
    };
  }

  private checkPhonenumberValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | any => {
      if (this.newConcatenatedValue) {
        if ((this.newConcatenatedValue?.length < 10 || this.newConcatenatedValue?.length > 11) || (this.newConcatenatedValue[0] != 0)) {
          this.phoneNumberCheckValidation = true;
        } else {
          this.phoneNumberCheckValidation = false;
        }
      } else {
        this.phoneNumberCheckValidation = false;
      }
      return this.phoneNumberCheckValidation;
    }
  }

  onBlurTelephone() {
    const telFirst = this.registrationForm?.value.telephoneFieldOne;
    const telSecond = this.registrationForm?.value.telephoneFieldTwo;
    const telThird = this.registrationForm?.value.telephoneFieldThree;
    if (telFirst === null || telSecond === null || telThird === null) {
      // this.showError = true;
      this.phoneNumberCheckValidation = false;
    }
    else {
      if (telFirst?.length < 2 || telSecond?.length < 2 || telThird?.length < 2) {
        this.phoneNumberCheckValidation = true;
      } else {
        this.newConcatenatedValue = telFirst + telSecond + telThird;
        this.registrationForm.controls['telephone'].setValue(this.newConcatenatedValue);
      }
    }
  }


  private initializeForm(): void {
    this.registrationForm = new FormGroup({
      methodOfConfirmationOfConsent: new FormControl('', Validators.required),
      relationship: new FormControl('', Validators.required),
      kanjiFirstName: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(hasKanjiChar)
        ])),
      kanjiLastName: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(hasKanjiChar)
        ])),
      kanaFirstName: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(hasKannaChar)
        ])),
      kanaLastName: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(hasKannaChar)
        ])),
      gender: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(null, [Validators.required, this.ageRange(18, 130)]),
      zipCode: new FormControl(null, [Validators.required,
      Validators.minLength(6),
      Validators.maxLength(8),
      Validators.pattern(hasNumber)
      ]),
      firstAddress: new FormControl(null, [Validators.required]),
      secondAddress: new FormControl(null, [Validators.required, Validators.pattern(hasAddressChar)]),
      telephone: new FormControl(null, [this.checkPhonenumberValidation()]),
      telephoneFieldOne: new FormControl(null, [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(5),
      Validators.pattern(zeroNum)
      ]),
      telephoneFieldTwo: new FormControl(null, [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(4),
      Validators.pattern(hasNumber)
      ]),
      telephoneFieldThree: new FormControl(null, [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(4),
      Validators.pattern(hasNumber)
      ]),
    });
  }

  ngOnDestroy(): void {
    if (this.simplifiedCWSRegSubscription) this.cwsqueryParamsSubscription.unsubscribe();
    if (this.simplifiedCWSRegSubscription) this.simplifiedCWSRegSubscription.unsubscribe()
  }

  formatDOB(input:any){
    console.log('triggered');
    let value = input.target.value;
    // Remove any non-digit characters from the input
    const digitsOnly = this.convertFullWidthNumbersToHalfWidth(value);
    // Ensure that the length is at most 10 characters
    const truncated = digitsOnly.substring(0, 10);
    console.log(truncated)
    // Format the DOB YYYY/mm/dd
    let formatted = '';
    // Check for first block 
    if (truncated.length > 4 && !truncated.includes('/')) {
      formatted += truncated.substring(0, 4) + '/';
    } 
    // Check for second block 
    if (truncated.length > 2 && !truncated.includes('/')) {
      formatted += truncated.substring(4, 2) + '/';
      formatted += truncated.substring(2, 2);
    } 
  }

  /** function on blur to append hypen on zipcode after three numbers only when length is 7*/
  addHyphen(event: any) {
    this.zipCodeErrMessage = "";
    this.registrationForm.controls['firstAddress'].setValue('');
    let value = event.target.value;
    let new_value;
    let finalVal;
    new_value = this.convertFullWidthNumbersToHalfWidth(value);
    let valarr = new_value.split('');
    this.numberValue = value;
    if (valarr.length < 7) {
      finalVal = new_value;
      this.zipCodeErrMessage = errorMessageInput.zipCodeErrMessage;
    }
    else {
      if (!valarr.includes('-') && new_value !== '') {
        new_value = `${new_value.substring(0, 3)}-${new_value.substring(3)}`
      }
      finalVal = new_value.slice(0, 8);
      this.registrationForm.controls['zipCode'].setValue(finalVal);
      this.getAddressForZipCode();
    }

  }
  /** function to convert full width number to half width*/
  convertFullWidthNumbersToHalfWidth(input: string): string {
    return input.replace(/[\uFF10-\uFF19]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 0xFEE0);
    });
  }

  submitForm() {
    let data = this.registrationForm.value;
 
    if(this.familyResigstarionService.dataFromConfirmationToInput?.firstAddress?.addressKanji){
    this.addressAutoWholeObj = this.familyResigstarionService.dataFromConfirmationToInput?.firstAddress;
    }
    else{
    this.addressAutoWholeObj = data.firstAddress;
    }

    let dob_modified = this.datepipe.transform(data.dateOfBirth, 'yyyy/MM/dd');
    if (!data.telephone) {
      this.onBlurTelephone();
    }
    if (this.registrationForm.valid) {
      let obj = {
        methodOfConfirmation: data.methodOfConfirmationOfConsent.dataValue ? data.methodOfConfirmationOfConsent.dataValue : this.familyResigstarionService.inputPageFormData.methodOfConfirmation,
        relationship: data.relationship?.dataValue ? data.relationship?.dataValue : this.familyResigstarionService.inputPageFormData.relationship,
        kanjiFirstName: data.kanjiFirstName,
        kanjiLastName: data.kanjiLastName,
        kanaFirstName: data.kanaFirstName,
        kanaLastName: data.kanaLastName,
        gender: data.gender?.dataValue ? data.gender?.dataValue : this.familyResigstarionService.inputPageFormData.gender,
        dob: dob_modified ? dob_modified : this.familyResigstarionService.dataFromConfirmationToInput.dateOfBirth,
        zipCode: data.zipCode,
        addressAutofilled: this.addressAutoWholeObj,
        addressContinued: data.secondAddress,
        telephoneFieldOne: data.telephoneFieldOne,
        telephoneFieldTwo: data.telephoneFieldTwo,
        telephoneFieldThree: data.telephoneFieldThree,
        telephone: data.telephone,
        date_original: data.dateOfBirth
      }
      this.familyResigstarionService.selectedMember = null;
      this.familyResigstarionService.inputPageFormData = obj;
      this.familyResigstarionService.dataFromConfirmationPageFlag = false;
      this.router.navigate(['/family-registration/confirmation-page']);
    }
    else {
      this.showError = true;
    }
  }

  formatDate(date: Date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split('T')[0]
  }

  createYearList() {
    let curr_year = new Date().getFullYear();
    let years = [];
    let startYear = 1910;
    while (startYear <= curr_year) {
      this.Yearlist.push(startYear++);
    }

  }
  filterYear(e: any): void {
    let year = e.target.value;
    let yearList = this.Yearlist.filter(x => x === year);
  }

  filterMonth(e: any): void {
    let month = e.target.value;
    let monthList = this.Yearlist.filter(x => x === month);
  }

  filterDay(e: any) {
    let day = e.target.value;
    let dayList = this.DayList.filter(x => x === day);
  }

  createDayList() {
    for (let i = 1; i <= 31; i++) {
      let day = i < 10 ? '0' + i : i;
      this.DayList.push(day)
    }
  }
  get f() {
    return this.registrationForm.controls;
  }

  changeDate(event: any) {
    // let date = this.formatDate(event.target.value);
    // let date = event.target.value;
    // this.formatDateOnSelection(date);
  }

  kanaNameValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      const inputStr = control?.value;
      for (let i = 0; i < inputStr.length; i++) {
      }
      return null;
    };
  }

  private regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): any => {
      return control?.value && regex.test(control.value) ? null : error;
    };

  }

  getAddressForZipCode() {
    this.zipCodeErrMessage = "";
    this.zipCodeNewValue = this.registrationForm.value.zipCode;
    this.zipCodeNewValue = this.zipCodeNewValue.replace(/-/g, '');
    this.familyResigstarionService.getAddressFieldForZipcode(this.zipCodeNewValue).subscribe((res) => {
      if (res) {
        this.zipCodeErrMessage = "";
        let response = res;
        this.addressResponseList = response?.results;
        if (this.addressResponseList.length <= 0) {
          this.registrationForm.controls['firstAddress'].setValue('');
          this.zipCodeErrMessage = errorMessageInput.zipCodeErrMessageNoRecord.Err_404;
        }
      } else {
        this.addressResponseList = [];
        this.registrationForm.controls['firstAddress'].setValue('');
      }
    }, error => {
      console.log(error);
      if (error.status === 500 || error.status === 503 || error.status === 504) {
        this.responseErrMessage = errorMessageInput.responseErrMessage.Err_500_503_504;
      } else if (error.status === 400) {
        this.responseErrMessage = errorMessageInput.errMessageBadRequest.Err_400;
      } else if (error.status === 404) {
        this.responseErrMessage = errorMessageInput.zipCodeErrMessageNoRecord.Err_404;
      }
      this.loader.hide();
    });
  }

  setValueForForm() {
    this.registrationForm.controls['methodOfConfirmationOfConsent'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.methodOfConfirmationOfConsent);
    this.registrationForm.controls['relationship'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.relationship);
    this.registrationForm.controls['kanjiFirstName'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.kanjiFirstName);
    this.registrationForm.controls['kanjiLastName'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.kanjiLastName);
    this.registrationForm.controls['kanaFirstName'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.kanaFirstName);
    this.registrationForm.controls['kanaLastName'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.kanaLastName);
    this.registrationForm.controls['gender'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.gender);
    this.registrationForm.controls['zipCode'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.zipCode);
    this.registrationForm.controls['firstAddress'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.firstAddress.addressKanji);
    this.registrationForm.controls['secondAddress'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.secondAddress);
    this.registrationForm.controls['telephoneFieldOne'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.telephoneFieldOne);
    this.registrationForm.controls['telephoneFieldTwo'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.telephoneFieldTwo);
    this.registrationForm.controls['telephoneFieldThree'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.telephoneFieldThree);
    this.registrationForm.controls['telephone'].setValue(this.familyResigstarionService.dataFromConfirmationToInput.telephone);
  }

}
