import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appDateInputFormat]'
})
export class DateInputFormatDirective {

  private regex: RegExp = new RegExp(/^[0-9\uFF10-\uFF19]*$/);
 private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
 constructor(private el: ElementRef) { }
 
 @HostListener('keydown', ['$event'])
 onKeyDown(event: KeyboardEvent) {
   if (this.specialKeys.indexOf(event.key) !== -1) {
     return;
   }
   if(!event.key.match(this.regex)) {
    event.preventDefault();
   }
  }
 
 @HostListener('paste', ['$event'])
 onPaste(event: ClipboardEvent) {
   let clipboarddata = event.clipboardData?.getData('text');
   if(clipboarddata && !clipboarddata.match(this.regex)) {
    event.preventDefault();
   }
 }

 @HostListener('input', ['$event'])
 onInput(event: any) {
   const input = this.el.nativeElement.value.replace(/[^\d\uFF10-\uFF19]/g,'');
   this.el.nativeElement.value = input;
 }

}
