import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SideNavConfigItem } from '@cws-ui/ngx-cws-app-layout';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, map, of, switchMap } from 'rxjs';

import { common_urls_gasa } from 'src/environments/environment';
import { create_UUID } from 'src/app/utils/commons';
import { FUND_HISTORY_DATA, FUND_HISTORY_PAYLOAD } from '../shared/models/fund-history-model';
import { FUND_GRAPH_DATA, FUND_GRAPH_PAYLOAD } from '../shared/models/fund-graph-model';
import { POLICY_SEARCH_DATA, POLICY_SEARCH_RESPONSE_DATA } from '../shared/models/policy-search-model';
import { CaseCreationRequest, CaseCreationResponse, SelectableFunds } from '../shared/models/case-creation-model';
import { ExchangeRatesData } from '../shared/models/exchange-rates-model';
import { fundDataMapped, policyStatusMapped } from '../shared/util';
import { CURRENCIES, TIME_RANGE_OPTS } from '../shared/constants';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class GasaFundEnhancementService {
  apiBaseUrl: String = common_urls_gasa.bffBaseUrl;  // changed for fund
  policyNumber!: string;
  smallProdCD!: string | null;
  largeProdCD!: string | null;
  cwsId!: string;
  gasaBackUrl!: string;
  id!: string;
  accumulatedFundItemLeftMenu: BehaviorSubject<SideNavConfigItem | null> = new BehaviorSubject<SideNavConfigItem | null>(null);
  // Subject which handles the visibility of the warning message for prevention of browser back button use
  showBrowserBackNavigationWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  policySearchData: BehaviorSubject<POLICY_SEARCH_DATA | null> = new BehaviorSubject<POLICY_SEARCH_DATA | null>(null);
  fundGraphData: BehaviorSubject<FUND_GRAPH_DATA | null> = new BehaviorSubject<FUND_GRAPH_DATA | null>(null);
  fundHistoryData: BehaviorSubject<FUND_HISTORY_DATA | null> = new BehaviorSubject<FUND_HISTORY_DATA | null>(null);
  exchangeRates: BehaviorSubject<ExchangeRatesData[] | null> = new BehaviorSubject<ExchangeRatesData[] | null>(null);
  currencySet: BehaviorSubject<string> = new BehaviorSubject(CURRENCIES['JPY']);
  targetResize: Subject<void> = new Subject();
  SelectableFunds: BehaviorSubject<SelectableFunds | null> = new BehaviorSubject<SelectableFunds | null>(null);

  constructor(private httpClient: HttpClient, private oidcSecurityService: OidcSecurityService, private cwsRedirectService: CwsRedirectService,) {
  }

  /* Function to combine api responses in a single observable */
  getAllPolicyDetails(): Observable<[FUND_GRAPH_DATA | null, FUND_HISTORY_DATA | null]> {
    return combineLatest([
      this.policySearch().pipe(
        switchMap((response) => {
          const policy = response[0].policy;
          // swap the target fund value at 0th index 
          if (policy.funds) {
            fundDataMapped(policy);
            policyStatusMapped(policy);
          }
          this.policySearchData.next(policy);
          /* Passing 'productFamilyCodeStandard1' & 'productFamilyCodeStandard2' as params after policy search response */
          return this.fundGraph(this.largeProdCD, this.smallProdCD)
        }), catchError(() => {
          console.error('Error in fetching policy search data & fund graph data')
          // redirect to error page
          this.cwsRedirectService.redirectToCWSErrorPage();
          return of(null)
        })),
      this.fundSwitchHistory().pipe(catchError(() => {
        console.error('Error in fetching fund switching history data')
        return of(null)
      }))
    ]).pipe(
      map(([fundGraphResponse, fundHistoryResponse]) => {
        // handle completion
        return [fundGraphResponse, fundHistoryResponse]
      })
    );
  }

  /* Function to get policy search data */
  policySearch(): Observable<POLICY_SEARCH_RESPONSE_DATA[]> {
    const payload = {
      "funds": 1, //Mandatory - Value will be 1
      "beneficiaries": 1,//Mandatory - Value will be 1
      "policies": [
        {
          "lProdCode": this.largeProdCD,
          "sProdCode": this.smallProdCD,
          "policyNumber": this.policyNumber
        }
      ]
    }
    const cwsTraceId = create_UUID();
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        return this.httpClient.post<POLICY_SEARCH_RESPONSE_DATA[]>(`${this.apiBaseUrl}/policySearch/${cwsTraceId}`, payload, requestOptions);
      }))
  }

  /* Function to get fund graph data  */
  fundGraph(codeSt1: string|null, codeSt2: string|null, timerange: string = TIME_RANGE_OPTS['2y']): Observable<FUND_GRAPH_DATA | null> {
    const payload: FUND_GRAPH_PAYLOAD = {
      "policyNumber": this.policyNumber, // mandatory
      "duration": timerange  //optional
    }
    // either productFamilyCodeStandard1 or productFamilyCodeStandard1 is mandatory
    if (codeSt1)
      payload["productFamilyCodeStandard1"] = codeSt1;
    else
      payload["productFamilyCodeStandard2"] = codeSt2;

    const cwsTraceId = create_UUID();
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        return this.httpClient.post<FUND_GRAPH_DATA | null>(`${this.apiBaseUrl}/fundGraphSearch/${cwsTraceId}`, payload, requestOptions);
      }))
  }

  /* Function to get fund switch history data  */
  fundSwitchHistory(): Observable<FUND_HISTORY_DATA | null> {
    const payload: FUND_HISTORY_PAYLOAD = {
      policyNumber: this.policyNumber,// Mandatory
      smallProductCd: this.smallProdCD, // either largeProductCd or smallProductCd is mandatory
      largeProductCd: this.largeProdCD,
      recordType: "SWITCH" // mandatory
    }
    const cwsTraceId = create_UUID();
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        return this.httpClient.post<FUND_HISTORY_DATA | null>(`${this.apiBaseUrl}/fundSwitch/history/${cwsTraceId}`, payload, requestOptions);
      }))
  }

  /* Function to get exchange rates data  */
  getExchangeRate(code: string, effectiveDate: string): Observable<ExchangeRatesData[] | null> {
    let params = new HttpParams();

    params = params.append('effectiveDate', effectiveDate);
    params = params.append('currencyCode', code);

    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        let requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          }),
          params: params
        };
        return this.httpClient.get<ExchangeRatesData[] | null>(`${this.apiBaseUrl}/rates/exchange`, requestOptions);
      }))
  }

  /**
   * This api call is used for cases creation when we submit request on the confirmation page of fund switching
   */
  caseCreation(payload: CaseCreationRequest) {
    const cwsTraceID = create_UUID();
    const apiEndpoint = `${this.apiBaseUrl}/caseCreate/${cwsTraceID}`;

    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        return this.httpClient.post<CaseCreationResponse>(apiEndpoint, payload, requestOptions);
      }))
  }

  /**
   * This method fetches the userData and and persists it across the application, Case creation api gets CWS_ID from here
   */
  getUserData() {
    return this.oidcSecurityService.getUserData();
  }

  getSelectabledFunds(payload: any) {
    const cwsTraceID = create_UUID();
    const apiEndpoint = `${common_urls_gasa.bffBaseUrl}/productSearch/${cwsTraceID}`;
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        return this.httpClient.post<SelectableFunds>(apiEndpoint, payload, requestOptions);
      }))
  }
}