import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, take } from 'rxjs';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LoaderService } from 'src/app/service/loader.service';
import { common_urls } from 'src/environments/environment';

@Component({
  selector: 'app-completion-page',
  templateUrl: './completion-page.component.html',
  styleUrls: ['./completion-page.component.scss']
})
export class CompletionPageComponent implements OnInit, OnDestroy {
  queryParamsSubscription!: Subscription;
  pageTitle:string = '';

  @Input() inputFromParent: any;

  constructor(private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
              private inactivityService: InactivityService, private loaderService: LoaderService,
              private translateService: TranslateService, private titleService: Title,
              private cwsRedirectService: CwsRedirectService) {}

  ngOnInit(): void {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.loaderService.hide();
    this.inactivityService.startTrackingInactivity();
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      // console.log('isAuthenticated', isAuthenticated)
    });
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) this.queryParamsSubscription.unsubscribe();
  }
  
  /* function to redirect user on cws */
  redirectToCWS() {
    // this.inactivityService.stopTrackingInactivity();
    // // window.location.href = this.cwsBackUrl;
    // const url: string = sessionStorage.getItem('cwsBackUrl') || '';
    // window.open(decodeURIComponent(url), '_self');
    this.cwsRedirectService.redirectToCWSBack();
  }

}
