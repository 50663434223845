import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FamilyRegistrationComponent } from "./family-registration/family-registration.component";
import { SelectPolicyComponent } from "./select-policy/select-policy.component";
import { SelectMemberComponent } from "./select-member/select-member.component";
import { InputPageComponent } from "./input-page/input-page.component";
import { CompletionPageComponent } from "./completion-page/completion-page.component";
import { ConfirmationPageComponent } from "./confirmation-page/confirmation-page.component";


const routes: Routes = [
    {
      path: 'home', component: FamilyRegistrationComponent
    },
    {
      path: 'select-policy', component: SelectPolicyComponent
    },
    {
      path: 'input-page', component: InputPageComponent
    },
    {
      path: 'confirmation-page', component: ConfirmationPageComponent
    },
    {
      path: 'completion-page', component: CompletionPageComponent
    },
    {
      path: 'select-members', component: SelectMemberComponent
    },
    {
      path: '', redirectTo: 'home', pathMatch: 'full'
    }
  ];
  
@NgModule({
imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class RegistrationRoutingModule{

}