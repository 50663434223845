import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { MaterialModule } from './material/material.module';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { InfoModalComponent } from './shared/info-modal/info-modal.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LogoutModalComponent } from './shared/logout-modal/logout-modal.component';
import { SurveyLogoutModalComponent } from './shared/survey-logout-modal/survey-logout-modal.component';
import { translateConfig } from './translate-config';
// import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { AppLayoutComponent, FetchMenuService } from '@cws-ui/ngx-cws-app-layout';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CwsFooterComponent } from './shared/cws-footer/cws-footer.component';
import { CwsHeaderComponent } from './shared/cws-header/cws-header.component';
import { LogoutButtonComponent } from './shared/logout-button/logout-button.component';
import { ConfirmationModalComponent } from './shared';
import { RegistrationModule } from './registration/registration.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    LogoutButtonComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SurveyLogoutModalComponent,
    LogoutModalComponent,
    InfoModalComponent,
    CwsHeaderComponent,
    CwsFooterComponent,
    ConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    AuthConfigModule,
    NoopAnimationsModule,
    MaterialModule,
    AppLayoutComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(translateConfig),
    HttpClientModule,
    RegistrationModule
  ],
  providers: [FetchMenuService],
  bootstrap: [AppComponent]
})
export class AppModule { }
