import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
 selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {
  constructor(private ngControl: NgControl) { }
  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const numericValue = value.replace(/\D/g, ''); // Remove non-digit characters
    const transformedValue = numericValue.replace(/(\d)(?=\d)/, '$1');
    this.ngControl.control?.setValue(transformedValue, { emitEvent: false });
  }
 }
