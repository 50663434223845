import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appJapaneseInput]'
})
export class JapaneseInputDirective {
  // Regex for full-width and half-width numbers only
  private allowedCharacters: RegExp = /^[\uFF10-\uFF19\u0030-\u0039]*$/;
  constructor(private el: ElementRef, private control: NgControl) { }
  // Listen to input events
  @HostListener('input', ['$event']) onInputChange(event: InputEvent): void {
    const inputValue = this.el.nativeElement.value;
    // Remove invalid characters by applying regex
    const filteredValue = this.filterInvalidCharacters(inputValue);
    // If the value has changed, update the form control
    if (inputValue !== filteredValue) {
      this.el.nativeElement.value = filteredValue;
      this.control.control?.setValue(filteredValue);
    }
  }
  // This method removes characters not matching the regex
  private filterInvalidCharacters(value: string): string {
    return value.split('').filter(char => this.allowedCharacters.test(char)).join('');
  }
}
