import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, take } from 'rxjs';
import { CwsRedirectService } from 'src/app/service/cws-redirect.service';
import { FamilyRegistrationService } from 'src/app/service/family-registration.service';
import { InactivityService } from 'src/app/service/inactivity.service';
import { LoaderService } from 'src/app/service/loader.service';
import { common_urls } from 'src/environments/environment';
import { memberRelationship } from 'src/app/shared/constants-c360';
@Component({
  selector: 'app-select-policy',
  templateUrl: './select-policy.component.html',
  styleUrls: ['./select-policy.component.scss']
})
export class SelectPolicyComponent implements OnInit {
  queryParamsSubscription!: Subscription;
  pageTitle:string = '';
  myResponseList: any;
  newPolicyArray: any[] = [];
  tempArray: any[] = [];
  @Input() inputFromParent: any;
  statusCheckbox: Boolean = false;
  constructor(private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
              private inactivityService: InactivityService, private loaderService: LoaderService,
              private translateService: TranslateService, private titleService: Title,
              private cwsRedirectService: CwsRedirectService, private familyResigstarionService: FamilyRegistrationService) {}

  ngOnInit(): void {
    this.translateService.get('C360.title-c360-first-page').pipe(take(1)).subscribe((translatedTitle: string) => {
      this.pageTitle = translatedTitle;
      this.titleService.setTitle(this.pageTitle);
    });
    this.loaderService.hide();
    this.inactivityService.startTrackingInactivity();
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
    });
    this.getPolicyData();
  }

  
  getPolicyData(){
    let myParentDataValue = this.familyResigstarionService.parentData;
    this.myResponseList = myParentDataValue.policies;
    
  }

  onChange($event: any, value: any) {
    const newValue = value;
    const isChecked = $event.target.checked;

    if (isChecked === true) {
      this.newPolicyArray.push(newValue);
    } else if (isChecked === false) {
      let index = -1
      this.newPolicyArray.forEach((x, idx) => {
        if (x.policyID === newValue) {
          index = idx
        }
        if (index > -1) {
          return
        }
      })
      this.newPolicyArray.splice(index, 1);
    }else console.log('Failed');
    this.familyResigstarionService.transferData = this.newPolicyArray;
  }



  
  /* function to redirect user on cws */
  redirectToCWS() {
    this.cwsRedirectService.redirectToCWSBack();
  }

}
