import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { common_url_sur, common_urls_c360 } from 'src/environments/environment';
import { Observable, of, retry, switchMap, take } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FamilyRegistrationService {
  token: any;
  bffBaseSurUrl: String = common_url_sur.bffBaseSurUrl;
  //fetch for confirmation
  accountId: any;
  policyHolderName: any;
  inputPageFormData: any;
  public parentData: any; 

  public transferData: any;
  public selectedMember: any;
  public prevSelectedPolicyBoolean:boolean = false;
  public dataFromConfirmationToInput: any;
  public dataFromConfirmationPageFlag:boolean = false;
  url: any = common_urls_c360.bffBaseUrl;
  constructor(private httpClient: HttpClient, private oidcSecurityService: OidcSecurityService) { }

  getFamilyRegPolicyDetails(): Observable<any> {
    return this.oidcSecurityService.getAccessToken().pipe((take(1)),
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          })
        };
        return this.httpClient
          .get(this.url + '/family-policy-check/registration-policies', requestOptions)
      }),
      retry({
        count: 2,
        delay: 2000,
        resetOnSuccess: true
      })
    )
  }

  getAddressFieldForZipcode(zipCode: number): Observable<any> {
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          })
        };
        return this.httpClient
          .get(this.url + '/address/' + zipCode, requestOptions)
      }),
      retry({
        count: 2,
        delay: 2000,
        resetOnSuccess: true
      })
    )
  }
  onSubmit(data: any): Observable<any> {
    return this.oidcSecurityService.getAccessToken().pipe((take(1)),
      switchMap((accessToken) => {
        const requestOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json'
          })
        };
        return this.httpClient
          .post(this.url + '/family-policy-check/family-member/registration', data, requestOptions)
      })
    )
  }



  setToken(data: any) {
    this.token = data?.url;
  }
  getToken() {
    return this.token;
  }
}
