import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { common_url_sur } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SimplifiedCwsRegistrationService {
  token : any;
  bffBaseSurUrl: String = common_url_sur.bffBaseSurUrl;

  constructor(private httpClient: HttpClient) { }

  getDecreptedData():Observable<any>{
    const url=`${this.bffBaseSurUrl}/decrypt/${this.token}`
    const requestOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
      };
      let headers=new HttpHeaders();
      // headers=headers.set({'Content-Type': 'application/json';})
    return this.httpClient.get(`${this.bffBaseSurUrl}/email/${this.token}`,requestOptions)
  }

  onSubmit(data:any):Observable<any>{
    const requestOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
      };
    return this.httpClient.post(`${this.bffBaseSurUrl}/submit`,data,requestOptions)
  }

  setToken(data:any) {
    this.token = data?.url;
  }
  getToken(){
    return this.token;
  }
}
