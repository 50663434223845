export const memberRelationship = [
    { _id: '0', engValue: 'Husband', japValue: '夫' },
    { _id: '1', engValue: 'Wife', japValue: '妻' },
    { _id: '2', engValue: 'Grandfather', japValue: '祖父' },
    { _id: '3', engValue: 'Grandmother', japValue: '祖母' },
    { _id: '4', engValue: 'Father', japValue: '父' },
    { _id: '5', engValue: 'Mother', japValue: '母' },
    { _id: '6', engValue: 'Older Brother', japValue: '兄' },
    { _id: '7', engValue: 'Older Sister', japValue: '姉' },
    { _id: '8', engValue: 'Younger Brother', japValue: '弟' },
    { _id: '9', engValue: 'Younger Sister', japValue: '妹' },
    { _id: '10', engValue: 'Child', japValue: '子' },
    { _id: '11', engValue: 'Uncle', japValue: '伯叔父' },
    { _id: '12', engValue: 'Aunt', japValue: '伯叔母' },
    { _id: '13', engValue: 'Nephew', japValue: '甥' },
    { _id: '14', engValue: 'Niece', japValue: '姪' },
    { _id: '15', engValue: 'Grandchild', japValue: '孫' },
  ];

export const seccessor_order_ja = ['夫', '妻', '子', '孫', '父', '母', '兄', '姉', '弟', '妹', '甥', '姪', '伯叔父', '伯叔母', '祖父', '祖母'];

const seccessor_order = ['husband', 'wife', 'child', 'grandchild', 'father', 'mother', 'older brother', 'older sister', 'younger brother', 'younger sister', 'nephew', 'niece', 'uncle', 'aunt', 'grandfather', 'grandmother'];

//input page
 export const hasSpecialCharacter = new RegExp(/[*@!#%&()^~{}]+/)
 export const hasNumber = new RegExp(/^[0-9-]+$/)
 export const hasRepitativeChar = new RegExp(/(\w)\1{4,}/);
 export const hasConsecutiveChar = new RegExp(/(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789)+/)
 export const hasLowerChar = new RegExp(/.*[a-z].*/);
 export const hasKannaChar = new RegExp(/^[ヴァ-ンー・]*$/);
 export const hasKanjiChar = new RegExp(/^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]+$/);
 export const hasUpperChar = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([A-Za-z0-9@!#$%^&*_+=-|:;,.?]+)$/);
 export const hasAddressChar = new RegExp(/^[\uFF61-\uFF9F0-9a-zA-Z-]+$/);
 export const zeroNum = new RegExp(/(0\d)/);
 export const methodOfConfirmationOfConsent: any[] = [
    { _id: '0', dataValue: '対面' },
    { _id: '1', dataValue: '電話' },
    { _id: '2', dataValue: '書面（手紙など）' },
    { _id: '3', dataValue: 'メールなど' }
  ];

export const memberRelationshipInput = [
    { _id: '0', dataValue: '夫' },
    { _id: '1', dataValue: '妻' },
    { _id: '2', dataValue: '子' },
    { _id: '3', dataValue: '孫' },
    { _id: '4', dataValue: '父' },
    { _id: '5', dataValue: '母' },
    { _id: '6', dataValue: '兄' },
    { _id: '7', dataValue: '姉' },
    { _id: '8', dataValue: '弟' },
    { _id: '9', dataValue: '妹' },
    { _id: '10', dataValue: '甥' },
    { _id: '11', dataValue: '姪' },
    { _id: '12', dataValue: '伯叔父' },
    { _id: '13', dataValue: '伯叔母' },
    { _id: '14', dataValue: '祖父' },
    { _id: '15', dataValue: '祖母' },
  ];

  export const  memberGender = [
    { _id: '男性', dataValue: '男' },
    { _id: '女性', dataValue: '女' },
  ];

  export const errorMessageRegistration = {
    errMessageBadRequest : {
      Err_400: "予期しないエラーが発生しました。サポートまでににお問い合わせください。"
    },
    errMessageNoRecord : {
     Err_404: "データが見つかりません。"
    }, 
    responseErrMessage : {
      Err_500_503_504: "予期しないエラーが発生しました。しばらくしてからもう一度お試しください。"
     }
  }

  export const errorMessageInput = {
    zipCodeErrMessage :"有効な郵便番号を入力してください。",
    zipCodeErrMessageNoRecord : {
      Err_404:"データが見つかりません。"
    },
    responseErrMessage : {
      Err_500_503_504: "予期しないエラーが発生しました。しばらくしてからもう一度お試しください。"
     },
    errMessageBadRequest : {
      Err_400: "予期しないエラーが発生しました。サポートまでににお問い合わせください。"
    },
  }

  export const errorMessageConfirmation = {
    responseErrMessage : {
     Err_500_503_504: "予期しないエラーが発生しました。しばらくしてからもう一度お試しください。"
    },
    alert: "重要事項をお読みになりチェックボックスを選択してください。",
    errMessageBadRequest : {
      Err_400: "予期しないエラーが発生しました。サポートまでににお問い合わせください。"
    },
    errMessageNoRecord : {
     Err_404: "データが見つかりません。"
    }, 
  }

  export const familyregurl = "/family-registration/home";